export default (
  <div className={"about-us__text-wrapper"}>
    <p className={"about-us__text"}>
      SGL, Türkiye’deki Müvekkilleri İngiliz hukuk sistemine, İngiltere’deki Müvekkilleri ise Türk hukuk sistemine entegre eden, iki ülke hukuk sistemleri arasında bir ara yüz görevi görmek üzere Londra’da kurulmuş bir hukuki danışmanlık şirketidir. SGL, müvekkillerin ihtiyaçlarına göre tasarlanmış yaratıcı çözümler üretmekte ve Türkiye ve İngiltere ile ilgili ticari veya kişisel ilişkisi olan kişi ve kurumlara çeşitli alanlarda profesyonel hukuki destek sağlamaktadır. Hem Londra Barosu hem de Istanbul Barosu’na kayıtlı olmasının ayrıcalığı ile İngiltere’de ve Türkiye’de yatırım yapmak isteyen veya herhangi bir hukuki ihtiyacı olan müvekkillerine en güvenilir, kapsamlı ve kaliteli hizmeti sunmak için çalışmaktadır.
    </p>

    <p className={"about-us__text"}>
      SGL, İngiliz hukuku ve Türk hukuku ile ilgili danışmanlık hizmetlerine ek olarak  doğum, evlilik belgesi ve vekaletname düzenleme, İngiltere’de noter tasdiki ve apostil hizmetleri, Londra’da gayrimenkul alımı, İngiltere’de marka ve patent tescili, Türk mahkemelerinde alınmış mahkeme kararlarının İngiltere’de tanıma ve tenfizi, İngiltere ve Türkiye arasındaki ticari ilişkiler ile ilgili sözleşmelerin hazırlanması ve incelenmesi, İngiltere’de şirket veya şube kuruluşu, İngiltere’de açılması gereken davaların takibi ve İngiltere’ye yapılacak yatırımlar ile ilgili danışmanlık vb. gibi bir çok konuda tek noktadan Türkçe ve İngilizce destek sağlamaktadır.
    </p>
  </div>
);
