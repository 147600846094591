import React, {Component} from "react";

import i18nRepo from "../../../i18n-repo/i18nRepo";

import {Galata} from "../../../assets";
import {Selin} from "../../../assets";

import {ABOUT_FOUNDER, ABOUT_SGL} from "./text";
import {connect} from "react-redux";

class AboutUs extends Component
{
  render ()
  {
    const {windowPreferences} = this.props;

    return (
      <div className={"landing-page"}>
        {
          windowPreferences.isTablet
          &&
          (
            <div className={"about-us__image-wrapper"}>
              <img
                src={Galata}
                className={"about-us__image"}
              />
            </div>
          )
        }

        <div className={"about-us__section-container"}>
          <div className={"about-us__section-wrapper about-us__left"}>
            {
              !windowPreferences.isTablet
              &&
              (
                <div className={"about-us__section-image-wrapper"}>
                  <img
                    className={"about-us__section-image"}
                    src={Galata}
                  />
                </div>
              )
            }

            <div className={"about-us__content-wrapper"}>
              <div className={"about-us__header-wrapper"}>
                <div className={"about-us__section-header-left"}>
                  {
                    i18nRepo.getWord("about_sgl")
                  }
                </div>
              </div>

              <div className={"about-us__section-content"}>
                {
                  ABOUT_SGL[this.props.language]
                }
              </div>
            </div>
          </div>

          <div className={"about-us__section-wrapper about-us__right"}>
            <div className={"about-us__content-wrapper"}>
              <div className={"about-us__header-wrapper"}>
                <div className={"about-us__section-header-left"}>
                  {
                    i18nRepo.getWord("about_founder")
                  }
                </div>
              </div>

              <div className={"about-us__section-content"}>
                {
                  ABOUT_FOUNDER[this.props.language]
                }
              </div>
            </div>
            {
              !windowPreferences.isTablet
              &&
              (
                <div className={"about-us__section-image-wrapper"}>
                  <img
                    className={"about-us__section-image"}
                    src={Selin}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  windowPreferences: state.appReducer.windowPreferences,
  language: state.appReducer.language
});

export default connect(mapStateToProps)(AboutUs);
