import React, {Component} from "react";
import {connect} from "react-redux";

class OuterClickHandler extends Component
{
  componentDidMount ()
  {
    document.addEventListener("mousedown", this.onClickOut);
  }

  componentWillUnmount ()
  {
    document.removeEventListener("mousedown", this.onClickOut);
  }

  setWrapperRef = (reference) =>
  {
    this.childReference = reference;
  };

  onClickOut = (event) =>
  {
    if (this.props.loading)
    {
      return;
    }

    if (this.childReference && !this.childReference.contains(event.target))
    {
      this.props.onClickOut();
    }
  };

  render ()
  {
    return (
      <div ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.appReducer.loading
});

export default connect(mapStateToProps)(OuterClickHandler);