import React, {Component} from "react";
import _ from "lodash";

import CommonHelper from "../../libs/CommonHelper";
import i18nRepo from "../../i18n-repo/i18nRepo";

import Button from "./Button";
import OuterClickHandler from "../elements/OuterClickHandler";

const TYPES = {
  warning: "warning",
  confirmation: "confirmation"
}

/**
 * A singleton component wraps app to display messages independent from any scope
 */
class MessageGate extends Component
{
  static messageCache = [];
  static confirmationCache = [];

  /**
   * Pushes given configs message to previously set references message cache
   *
   * @param config {{type: {String}, content: {String | JSX}}}
   */
  static message = (config) =>
  {
    const index = MessageGate.messageCache.length;

    let className = "";
    let iconName = "";
    let iconClass = "";
    switch (config.type)
    {
      case "success":
        className = "message__success";
        iconName = "fas fa-check-circle";
        iconClass = "message__check";
        break;

      case "error":
        className = "message__error";
        iconName = "fas fa-times-circle";
        iconClass = "message__cancel";
        break;

      default:
        className = "message__success";
        iconName = "fas fa-check-circle";
        iconClass = "message__check";
    }

    MessageGate.messageCache.push(
      <div
        className={className}
        key={"message" + index + "_" + Math.random()}
      >
        <div
          key={"message_inner" + index + "_" + Math.random()}
          className={"message__content-wrapper"}
        >
          <div
            key={"message_icon" + index + "_" + Math.random()}
            className={"message__icon"}
          >
            <i
              className={iconName + " " + iconClass}
            />
          </div>

          <div
            key={"message__content" + index + "_" + Math.random()}
            className={"message__content"}
          >
            {
              config.content
            }
          </div>
        </div>
      </div>
    );

    MessageGate.__singletonRef.setState((previousState, props) => ({
      messageCache: _.cloneDeep(MessageGate.messageCache)
    }));

    setTimeout(() =>
               {
                 MessageGate.messageCache.splice(0, 1);

                 MessageGate.__singletonRef.setState((previousState, props) => ({
                   messageCache: _.cloneDeep(MessageGate.messageCache)
                 }));
               }, 5000);
  };

  static confirm = (config) =>
  {
    config.type = TYPES.confirmation
    config._onOk = (index) =>
    {
      if (CommonHelper.isExist(config.onOk))
      {
        config.onOk();
      }

      MessageGate.confirmationCache.splice(index, 1);

      MessageGate.__singletonRef.setState((previousState, props) => ({
        confirmationCache: _.cloneDeep(MessageGate.confirmationCache)
      }));
    };
    config._onCancel = (index) =>
    {
      if (CommonHelper.isExist(config.onCancel))
      {
        config.onCancel();
      }

      MessageGate.confirmationCache.splice(index, 1);

      MessageGate.__singletonRef.setState((previousState, props) => ({
        confirmationCache: _.cloneDeep(MessageGate.confirmationCache)
      }));
    };
    config._outerClick = (index) =>
    {
      if (CommonHelper.isExist(config.onOuterClick))
      {
        config.onOuterClick();
      }

      MessageGate.confirmationCache.splice(index, 1);

      MessageGate.__singletonRef.setState((previousState, props) => ({
        confirmationCache: _.cloneDeep(MessageGate.confirmationCache)
      }));
    };

    MessageGate.confirmationCache.push(config);

    MessageGate.__singletonRef.setState((previousState, props) => ({
      confirmationCache: _.cloneDeep(MessageGate.confirmationCache)
    }));
  };

  static warning = (config) =>
  {
    config.type = TYPES.warning
    config._onOk = (index) =>
    {
      MessageGate.confirmationCache.splice(index, 1);

      MessageGate.__singletonRef.setState((previousState, props) => ({
        confirmationCache: _.cloneDeep(MessageGate.confirmationCache)
      }));
    };

    config._outerClick = (index) =>
    {
      MessageGate.confirmationCache.splice(index, 1);

      MessageGate.__singletonRef.setState((previousState, props) => ({
        confirmationCache: _.cloneDeep(MessageGate.confirmationCache)
      }));
    };

    MessageGate.confirmationCache.push(config);

    MessageGate.__singletonRef.setState((previousState, props) => ({
      confirmationCache: _.cloneDeep(MessageGate.confirmationCache)
    }));
  };

  constructor (props)
  {
    super(props);

    MessageGate.__singletonRef = this;

    this.state = {
      messageCache: [],
      confirmationCache: []
    };
  }

  componentDidMount ()
  {
    MessageGate.__singletonRef = this;
  }

  render ()
  {
    return (
      <div className={"message__gate"}>
        <div
          key={"messages"}
          className={"message__gate__messages"}
        >
          {
            this.state.messageCache.reverse()
          }
        </div>

        {
          CommonHelper.isInitialized(this.state.confirmationCache)
          &&
          (
            <div className={"message__confirmation-container"}>
              {
                this.state.confirmationCache.map((confirmation, index) => (
                  <OuterClickHandler
                    onClickOut={() => confirmation._outerClick(index)}
                    key={"confirmation" + index + "_" + Math.random()}
                  >
                    <div className={"message__confirmation-wrapper"}>
                      <p className={"message__text-wrapper"}>
                        {
                          confirmation.text
                        }
                      </p>

                      <div className={"message__button-container"}>
                        {
                          _.isEqual(confirmation.type, TYPES.confirmation)
                          &&
                          (
                            <Button
                              className={"message__button"}
                              text={CommonHelper.isExist(confirmation.cancelText)
                                    ? confirmation.cancelText
                                    : i18nRepo.getWord("cancel")}
                              onClick={() => confirmation._onCancel(index)}
                            />
                          )
                        }

                        <Button
                          className={"message__button message__action-button"}
                          text={CommonHelper.isExist(confirmation.okText)
                                ? confirmation.okText
                                : i18nRepo.getWord("ok")}
                          onClick={() => confirmation._onOk(index)}
                        />
                      </div>
                    </div>
                  </OuterClickHandler>
                ))
              }
            </div>
          )
        }

        {
          this.props.children
        }
      </div>
    );
  }
}

export default MessageGate;

export const message = MessageGate.message;
export const confirm = MessageGate.confirm;
export const warning = MessageGate.warning;
