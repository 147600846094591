import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _ from "lodash";

import i18nRepo from "../../i18n-repo/i18nRepo";

import Button from "../ui-kit/Button";

import {BigBen, Ladder, People, Vapur} from "../../assets/index";

const IMAGE_TIME = 5000;
const SCROLL_WIDTH = 5; // px

class LandingPage extends Component
{
  constructor (props)
  {
    super(props);

    // if this map changes we must also update grid-template-columns attribute in LandingPage.scss
    this.imageMap = [
      {
        key: 0,
        image: BigBen,
        text: i18nRepo.getSentence("the_legal_gateway_from_england_to_turkey")
      },
      {
        key: 1,
        image: Vapur,
        text: i18nRepo.getSentence("the_interface_between_english_and_turkish_legal_systems")
      },
      {
        key: 2,
        image: People,
        text: i18nRepo.getSentence("eliminate_the_legal_barriers_between_turkey_and_england")
      }
    ];

    this.imageInterval = null;
    this.imageWrapperRef = React.createRef();

    this.state = {
      currentImageIndex: 0
    };
  }

  componentDidMount ()
  {
    this.imageInterval = setInterval(() =>
                                     {
                                       this.setState((previousState, props) =>
                                                     {
                                                       const currentImageIndex = (previousState.currentImageIndex + 1) % this.imageMap.length;
                                                       this.imageWrapperRef.current.scrollTo(((this.imageWrapperRef.current.offsetWidth * currentImageIndex) - SCROLL_WIDTH), 0);

                                                       return {
                                                         currentImageIndex: currentImageIndex
                                                       };
                                                     });
                                     }, IMAGE_TIME);
  }

  componentWillUnmount ()
  {
    clearInterval(this.imageInterval);
  }

  onClickSectionOneButton = () =>
  {
    this.props.history.push("/about-us");
  };

  onClickSectionTwoButton = () =>
  {
    this.props.history.push("/services");
  };

  onClickSectionThreeButton = () =>
  {
    this.props.history.push("/contact");
  };

  render ()
  {
    return (
      <div className={"initial-page landing-page"}>
        <div className={"landing-page__images-container"}>
          <div
            className={"landing-page__images-wrapper"}
            ref={this.imageWrapperRef}
          >
            <div className={"landing-page__images-wrapper-cover"} />

            {
              this.imageMap.map((imageObj, index) => (
                <div
                  key={imageObj.key}
                  className={"landing-page__image"}
                >
                  <img
                    src={imageObj.image}
                    className={"landing-page__image__img"}
                  />

                  <div className={"landing-page__image__text"}>
                    {
                      imageObj.text
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        <div className={"landing-page__section-one"}>
          <div className={"landing-page__section-one__wrapper"}>
            <div className={"landing-page__section-one__text"}>
              {
                i18nRepo.getSentence("we_specialize_in_advising_clients")
              }
            </div>

            <div className={"landing-page__section-one__button-wrapper"}>
              <Button
                text={i18nRepo.getSentence("learn_more")}
                onClick={this.onClickSectionOneButton}
              />
            </div>
          </div>
        </div>

        <div className={"landing-page__section-two"}>
          <div className={"landing-page__section-two__wrapper"}>
            <div className={"landing-page__section-two__text"}>
              {
                i18nRepo.getSentence("why_choose_us")
              }
            </div>

            <div className={"landing-page__section-two__icon-container"}>
              <div className={"landing-page__section-two__icon-wrapper"}>
                <i className="fas fa-user-shield landing-page__section-two__icon" />

                <div className={"landing-page__section-two__icon-about-founder"}>
                  {
                    i18nRepo.getWord("integrity_and_trust")
                  }
                </div>
              </div>

              <div className={"landing-page__section-two__icon-wrapper"}>
                <i className="fas fa-pen-fancy landing-page__section-two__icon" />

                <div className={"landing-page__section-two__icon-about-founder"}>
                  {
                    i18nRepo.getWord("quality")
                  }
                </div>
              </div>

              <div className={"landing-page__section-two__icon-wrapper"}>
                <i className="fas fa-fighter-jet landing-page__section-two__icon" />

                <div className={"landing-page__section-two__icon-about-founder"}>
                  {
                    i18nRepo.getWord("responsiveness")
                  }
                </div>
              </div>

              <div className={"landing-page__section-two__icon-wrapper"}>
                <i className="fas fa-dot-circle landing-page__section-two__icon" />

                <div className={"landing-page__section-two__icon-about-founder"}>
                  {
                    i18nRepo.getWord("single_point_of_contact")
                  }
                </div>
              </div>

              <div className={"landing-page__section-two__icon-wrapper"}>
                <i className="fas fa-running landing-page__section-two__icon" />

                <div className={"landing-page__section-two__icon-about-founder"}>
                  {
                    i18nRepo.getWord("commitment")
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          _.isEqual(i18nRepo.languages.en, this.props.language)
          &&
          (
            <div className={"landing-page__section-one"}>
              <div className={"landing-page__section-one__wrapper"}>
                <div className={"landing-page__section-one__text"}>
                  {
                    i18nRepo.getSentence("service__sentence")
                  }
                </div>

                <div className={"landing-page__section-one__button-wrapper"}>
                  <Button
                    text={i18nRepo.getSentence("explore")}
                    onClick={this.onClickSectionTwoButton}
                  />
                </div>
              </div>
            </div>
          )
        }

        <div className={"landing-page__section-three"}>
          <img
            className={"landing-page__section-three__background"}
            src={Ladder}
          />

          <div className={"landing-page__section-three__wrapper"}>
            <div className={"landing-page__section-three__text"}>
              {
                i18nRepo.getSentence("local_turkish_legal_expertise")
              }
            </div>

            <div className={"landing-page__section-three__button-wrapper"}>
              <Button
                text={i18nRepo.getSentence("get_in_touch")}
                onClick={this.onClickSectionThreeButton}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.appReducer.language
});

export default withRouter(connect(mapStateToProps)(LandingPage));
