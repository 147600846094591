import _ from "lodash";

import {API_URL, axiosInstance} from "../config/server.config";

import CommonHelper from "../libs/CommonHelper";
import i18nRepo from "../i18n-repo/i18nRepo";

class Service
{
  // a static variable to use in default routes to achieve consistency
  static ROUTE_MAP = {
    "GET": "GET",
    "GET_BY_ID": "GET_BY_ID",
    "CREATE": "CREATE",
    "UPDATE_BY_ID": "UPDATE_BY_ID",
    "DELETE_BY_ID": "DELETE_BY_ID"
  };

  /**
   * @constructor
   * @param {String} route - Route to send request to
   * @param {Object} options
   * @param {Array.<String>} options.defaultRoutes - Default service function names to create default functions
   * @param {Boolean} options.authenticated - Whether service is authenticated or not
   */
  constructor (route, options = {})
  {
    this.defaultRoutes = CommonHelper.isExist(options.defaultRoutes)
                         ? options.defaultRoutes
                         : [];
    this.authenticated = options.authenticated;
    this.authenticationURLIdentifier = this.authenticated
                                       ? "/private"
                                       : "/public";

    this.route = route;

    this._generateRoutes();
  }

  /**
   * Prepares query with given query options
   *
   * @param queryOptions
   * @return {string}
   * @protected
   */
  _prepareQuery = (queryOptions) =>
  {
    try
    {
      const queryArray = [];

      const keys = Object.keys(queryOptions);

      for (let i = 0; i < keys.length; i++)
      {
        const key = keys[i];

        const value = _.toString(queryOptions[key]);

        queryArray.push(key + "=" + value);
      }

      return "?" + queryArray.join("&");
    }
    catch (error)
    {
      throw new Error(i18nRepo.getError("something_went_wrong"));
    }
  };

  /**
   * Returns data from success response.
   *
   * @param {Object} response
   * @return {*}
   * @protected
   */
  _extractDataFromSuccess = (response) =>
  {
    if (this.authenticated)
    {
      if (!CommonHelper.isExist(response.data.token))
      {
        throw new Error(); // todo error
      }

      localStorage.setItem("token", response.data.token);
    }

    return response.data;
  };

  /**
   * Returns data from error response.
   *
   * @param {Object} error
   * @return {*}
   * @protected
   */
  _extractDataFromError = (error) =>
  {
    return error.response.data;
  };

  /**
   * Prepares route for requests. Private and public identifiers set
   * two times in constructor. authentication identifiers should be set in this function
   *
   * @return {string}
   * @protected
   */
  _prepareRoute = () =>
  {
    return this.authenticationURLIdentifier + this.route; //
  };

  /**
   * Generates default routes which are set in the constructor. Sets given routes to this scope
   * @private
   */
  _generateRoutes = () =>
  {
    if (this.defaultRoutes.includes(Service.ROUTE_MAP["GET"]))
    {
      this.get = async (queryOptions, language) =>
      {
        try
        {
          const config = this._generateConfig(language);
          const query = "?query=" + JSON.stringify(queryOptions);

          const result = await axiosInstance.get(API_URL + this._prepareRoute() + query, config);
          return this._extractDataFromSuccess(result);
        }
        catch (error)
        {
          throw this._extractDataFromError(error);
        }
      };
    }
    if (this.defaultRoutes.includes(Service.ROUTE_MAP["GET_BY_ID"]))
    {
      this.getByID = async (_id, language) =>
      {
        try
        {
          const config = this._generateConfig(language);

          const result = await axiosInstance.get(API_URL + this._prepareRoute() + "/" + _id, config);
          return this._extractDataFromSuccess(result);
        }
        catch (error)
        {
          throw this._extractDataFromError(error);
        }
      };
    }
    if (this.defaultRoutes.includes(Service.ROUTE_MAP["CREATE"]))
    {
      this.create = async (body, language) =>
      {
        try
        {
          const config = this._generateConfig(language);

          this.deleteFields(body);

          const result = await axiosInstance.post(API_URL + this._prepareRoute() + "/", body, config);
          return this._extractDataFromSuccess(result);
        }
        catch (error)
        {
          throw this._extractDataFromError(error);
        }
      };
    }
    if (this.defaultRoutes.includes(Service.ROUTE_MAP["UPDATE_BY_ID"]))
    {
      this.updateByID = async (_id, version, body, language) =>
      {
        try
        {
          const config = this._generateConfig(language);
          const query = this._prepareQuery({version: version});

          const result = await axiosInstance.patch(API_URL + this._prepareRoute() + "/" + _id + query, body, config);
          return this._extractDataFromSuccess(result);
        }
        catch (error)
        {
          throw this._extractDataFromError(error);
        }
      };
    }
    if (this.defaultRoutes.includes(Service.ROUTE_MAP["DELETE_BY_ID"]))
    {
      this.deleteByID = async (_id, version, language) =>
      {
        try
        {
          const config = this._generateConfig(language);
          const query = this._prepareQuery({version: version});

          const result = await axiosInstance.delete(API_URL + this._prepareRoute() + "/" + _id + query, config);
          return this._extractDataFromSuccess(result);
        }
        catch (error)
        {
          throw this._extractDataFromError(error);
        }
      };
    }
  };

  /**
   * Returns config object for any request
   *
   * @return Object
   * @protected
   */
  _generateConfig = (language) =>
  {
    const config = {
      headers: {
        language: language
      }
    };

    if (this.authenticated)
    {
      config.headers.Authorization = localStorage.getItem("token");
    }

    return config;
  };

  deleteFields = (body) =>
  {
    const bodyKeys = Object.keys(body);

    for (let i = 0; i < bodyKeys.length; i++)
    {
      if (!CommonHelper.isExist(body[bodyKeys[i]]))
      {
        delete body[bodyKeys[i]];
      }
    }
  };
}

class SubServiceClass extends Service
{
  /**
   * @constructor
   * @param {String} preRoute
   * @param {Object} options
   * @param {Array.<String>} options.defaultRoutes - Default service function names to create default functions
   * @param {Boolean} options.authenticated - Whether service is authenticated or not
   */
  constructor (preRoute, options = {})
  {
    super(preRoute, options);
  }
}

export default Service;

export const SubService = SubServiceClass;