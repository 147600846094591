export default (
  <div className={"disclaimer"}>
    <div className={"disclaimer__header"}>
      DISCLAIMER
    </div>

    <div className={"disclaimer__text"}>
      The materials and information on this website have been prepared by SGL, for information
      purposes only. Nothing herein is intended as legal advice. Transmission of this information is
      not intended to create, and receipt of this information does not constitute an attorney-client
      relationship. This information may or may not reflect the most current legal developments.
      For these reasons, readers should not act upon this information without seeking professional
      advice. SGL assumes no responsibility or liability in connection with the information
      contained on this website.
    </div>
    <div className={"disclaimer__text"}>
      It is of significance to recognize that SGL does not practice law in the UK. We are not
      registered lawyers in the UK and do not have the authority or regulatory requirements to
      represent any client in the UK for any kind of legal work. SGL cannot provide any legal
      advice in connection with legal matters in the UK without corroboration from our associate
      firms in the UK. The position of SGL is to provide a convenient level of service to clients

      who have legal issues in Turkey and who require legal services within the jurisdiction of
      Turkey.
    </div>
    <div className={"disclaimer__text"}>
      This website is SGL’s copyright property. All rights are reserved.
    </div>
  </div>
);