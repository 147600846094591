import React, {Component} from "react";

class Arrow extends Component
{
  constructor (props)
  {
    super(props);

    let className = "";

    switch (this.props.position)
    {
      case "left":
        className = "arrow__left"
        break;

      case "right":
        className = "arrow__right"
        break;

      case "up":
        className = "arrow__up"
        break;

      case "down":
        className = "arrow__down"
        break;

      default:
        // noop
    }

    this.state = {
      className: className
    }
  }

  render ()
  {
    return (
      <React.Fragment>
        <div
          style={{padding: this.props.size}}
          className={"arrow " + this.state.className}
        />

        <div
          style={{width: this.props.size}}
        />
      </React.Fragment>
    );
  }
}

export default Arrow;