import sgl_en from "./about-sgl/en";
import sgl_tr from "./about-sgl/tr";

import founder_en from "./about-founder/en";
import founder_tr from "./about-founder/tr";

export const ABOUT_SGL = {
  en: sgl_en,
  tr: sgl_tr
}

export const ABOUT_FOUNDER = {
  en: founder_en,
  tr: founder_tr
};
