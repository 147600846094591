export const wordRepoTR = {
  about_founder: "Kurucu Hakkında",
  about_sgl: "SGL Hakkında",
  about_us: "Hakkımızda",
  accept: "Kabul et",
  address: "Adres",
  emailPhone: "Email/Telefon",

  coming_soon: "Yakında...",
  commitment: "Özveri",
  contact: "İletişim",
  content: "İçerik",

  disclaimer: "Hukuki uyarı",

  email: "E-posta",

  first_name: "İsim",

  insights: "Blog",
  integrity_and_trust: "Güven",

  last_name: "Soyisim",

  menu: "Menü",

  phone: "Telefon",
  phone_placeholder: "+90 5xx xxx xxxx",
  privacy_policy: "Gizlilik politikası",

  quality: "Kalite",

  responsiveness: "Hızlı geri dönüş",

  send: "Gönder",
  services: "Servisler",
  single_point_of_contact: "Tek noktadan temas",
  subject: "Konu",
  submit: "Kaydet",

  upload: "Yükle"
};
