export const sentenceRepoTR = {
  cookie_text: "Sitemizi çalıştırmak için bazı çerezler kullanıyoruz. Aynı zamanda cihazınızdaki bazı analiz ve pazarlama çerezlerini sitemizi geliştirmek için kullanıyoruz. Kabul et butonuna basarak çerezleri kabul etmiş olursunuz. Detaylı bilgi için lütfen ½&½#æGizlilik Politikaæ½&½mızı inceleyin.",

  eliminate_the_legal_barriers_between_turkey_and_england: "İngiltere ve Türkiye arasındaki hukuki engelleri aşın.",
  explore: "Keşfet!",

  follow_us: "Bizi takip et!",

  get_in_touch: "Şimdi iletişime geçin!",

  learn_more: "Daha fazla bilgi",
  load_more: "Daha fazla",
  local_turkish_legal_expertise: "SGL, hukuki sorunları sonuç odaklı bir yaklaşımla ele almakta ve yerli ve yabancı müvekkillerine ihtiyaçları doğrultusunda yaratıcı ve etkin çözümler sunmaktadır.",

  service__sentence: "Çeşitli problemler için servisler sağlıyoruz.",

  tell_us_what_you_think: "Bize ne düşündüğünü söyle.",
  thank_you_for_your_opinions: "Düşüncelerin için teşekkürler!",
  the_interface_between_english_and_turkish_legal_systems: "İngiliz ve Türk hukuk sistemleri arasındaki ara yüz.",
  the_legal_gateway_from_england_to_turkey: "Yerinden ve tek noktadan profesyonel hizmet.",

  we_are_here_to_help: "Hemen iletişime geç!",
  we_specialize_in_advising_clients: "SGL, müvekkillerine Birleşik Krallık ve Türkiye arasındaki hukuki konularda etkili ve güvenilir danışmanlık hizmetleri vermektedir.",
  why_choose_us: "Neden SGL?",

  your_message_is_successfully_send: "Mesajınız başarıyla gönderildi."
};
