import React, {Component} from "react";
import Arrow from "./Arrow";

class ArrowButton extends Component
{
  render ()
  {
    return (
      <button
        onClick={this.props.onClick}
        className={"arrow-button"}
      >
        <Arrow
          size={"4px"}
          position={this.props.position}
        />
      </button>
    );
  }
}

export default ArrowButton;