import _ from "lodash";

import {locales, initialLanguage} from "../config/app.config";

import CommonHelper from "../libs/CommonHelper";

import {errorRepoEN} from "./error/en";
import {errorRepoTR} from "./error/tr";

import {sentenceRepoEN} from "./sentence/en";
import {sentenceRepoTR} from "./sentence/tr";

import {wordRepoEN} from "./word/en";
import {wordRepoTR} from "./word/tr";

import "../style/classes.scss";

const LINK_IDENTIFIER = "½&½";
const LINK_INITIATOR = "#";
const LINK_WRAPPER = "æ";

class i18nRepo
{
  static languages = locales;
  static languageDisplays = {
    en: "English",
    tr: "Türkçe"
  };

  static word = {
    en: wordRepoEN,
    tr: wordRepoTR
  };

  static sentence = {
    en: sentenceRepoEN,
    tr: sentenceRepoTR
  };

  static error = {
    en: errorRepoEN,
    tr: errorRepoTR
  };

  static wordRepo = i18nRepo.word[initialLanguage];
  static sentenceRepo = i18nRepo.sentence[initialLanguage];
  static errorRepo = i18nRepo.error[initialLanguage];

  /**
   * Sets given languages repos.
   *
   * @param {String} language
   */
  static setLanguage (language)
  {
    if (!CommonHelper.isExist(i18nRepo.languages[language]))
    {
      throw new Error("Invalid arguments");
    }

    i18nRepo.wordRepo = i18nRepo.word[language];
    i18nRepo.sentenceRepo = i18nRepo.sentence[language];
    i18nRepo.errorRepo = i18nRepo.error[language];
  }

  /**
   * Get word with the given key.
   *
   * @param {String} key - word key to call
   * @param {Function} callback - function to call if given keys about-founder is processable
   * @returns
   */
  static getWord (key, callback = i18nRepo._noop)
  {
    return i18nRepo._processText(i18nRepo.wordRepo[key], callback);
  }

  /**
   * Get sentence with the given key.
   *
   * @param {String} key - sentence key to call
   * @param {Function} callback - function to call if given keys about-founder is processable
   * @returns
   */
  static getSentence (key, callback = i18nRepo._noop)
  {
    return i18nRepo._processText(i18nRepo.sentenceRepo[key], callback);
  }

  /**
   * Get error with the given key.
   *
   * @param {String} key - error key to call
   * @param {Function} callback - function to call if given keys about-founder is processable
   * @returns
   */
  static getError (key, callback = i18nRepo._noop)
  {
    return i18nRepo._processText(i18nRepo.errorRepo[key], callback);
  }

  /**
   * Function to process given string with keys provided at the begining of the file
   *
   * @param {String} text  - Text which will be processed
   * @param {Function} callback  - Function to call if an action exists and clicked
   * @returns
   */
  static _processText (text, callback = i18nRepo._noop)
  {
    let valueArray = text.split(LINK_IDENTIFIER);
    let returnArray = [];

    if (valueArray.length < 2)
    {
      return text;
    }

    for (let index = 0; index < valueArray.length; index++)
    {
      const textPart = valueArray[index];

      if (_.isEqual(textPart[0], LINK_INITIATOR) &&
          _.isEqual(textPart[1], LINK_WRAPPER) &&
          _.isEqual(textPart[textPart.length - 1], LINK_WRAPPER))
      {
        const stringWithoutIdentifiers = textPart.slice(2, textPart.length - 1);
        returnArray.push(
          <p
            onClick={callback}
            className={"i18n-repo__action i18n-repo__content"}
            key={index}
          >
            {
              stringWithoutIdentifiers + (CommonHelper.isExist(valueArray[index + 1]) && _.isEqual(valueArray[index + 1], " ")
                                          ? "\u00a0"
                                          : "")
            }
          </p>
        );
      }
      else
      {
        returnArray.push(
          <p
            className={"i18n-repo__content"}
            key={index}
          >
            {
              textPart + ((index < valueArray.length - 1)
                          ? "\u00a0"
                          : "")
            }
          </p>
        );
      }
    }

    return (
      <div>
        {
          returnArray
        }
      </div>
    );
  }

  /**
   * Simply does nothing
   * @private
   */
  static _noop = () =>
  {
    // does nothing
  };
}

export default i18nRepo;