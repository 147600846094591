import React, {Component} from "react";
import _ from "lodash";

import CommonHelper from "../../libs/CommonHelper";

class Form extends Component
{
  constructor (props)
  {
    super(props);

    let referenceMap = {};
    let itemMap = {};
    if (CommonHelper.isExist(props.formItems))
    {
      referenceMap = this._generateReferenceMap(props.formItems);
      itemMap = this._generateItemMap(props.formItems);
    }

    this.state = {
      references: referenceMap,
      formItems: itemMap
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext)
  {
    if (!_.isEqual(this.props.formItems, nextProps.formItems))
    {
      const referenceMap = this._generateReferenceMap(nextProps.formItems);
      const itemMap = this._generateItemMap(nextProps.formItems);

      this.setState((previousState, props) => ({
        references: referenceMap,
        formItems: itemMap
      }));
    }

    return true;
  }

  validateFields = () =>
  {
    let alerted = false;

    const alertedItems = [];
    const formItems = Object.values(this.state.formItems);
    for (let i = 0; i < formItems.length; i++)
    {
      const currentItem = formItems[i];

      if (CommonHelper.isExist(currentItem.config) &&
          currentItem.config.required &&
          !CommonHelper.isExist(this.state.references[currentItem.fieldName].current.getValue()))
      {
        alerted = true;
        alertedItems.push({
                            fieldName: currentItem.fieldName
                          });
      }
    }

    if (alerted)
    {
      const formItems = this.state.formItems;
      for (let i = 0; i < alertedItems.length; i++)
      {
        const currentAlert = alertedItems[i];
        formItems[currentAlert.fieldName].alerted = true;
      }

      this.setState((previousState, props) => ({
        formItems: formItems
      }));
    }

    return !alerted;
  };

  getFieldValues = (fieldNames = Object.keys(this.state.formItems)) =>
  {
    const result = {};

    for (let i = 0; i < fieldNames.length; i++)
    {
      const currentFieldName = fieldNames[i];
      result[currentFieldName] = this.state.references[currentFieldName].current.getValue();
    }

    return result;
  };

  getFieldValue = (fieldName) =>
  {
    return this.getFieldValues([fieldName]);
  };

  setFieldValues = (values) =>
  {
    const valueKeys = Object.keys(values);

    for (let i = 0; i < valueKeys.length; i++)
    {
      const currentKey = valueKeys[i];
      this.state.references[currentKey].current.setValue(values[valueKeys]);
    }
  };

  reset = () =>
  {
    const items = Object.values(this.state.formItems);

    const values = {};
    for (let i = 0; i < items.length; i++)
    {
      const currentItem = items[i];
      values[currentItem.fieldName] = null
    }

    this.setFieldValues(values);
  }

  submit = () =>
  {
    if (!this.validateFields())
    {
      return;
    }

    if (CommonHelper.isExist(this.props.onSubmit))
    {
      this.props.onSubmit(this.getFieldValues());
    }
  };

  onItemChange = (fieldName, value) =>
  {
    if (this.state.formItems[fieldName].alerted)
    {
      this.setState((previousState, props) => ({
        formItems: {
          ...previousState.formItems,
          [fieldName]: {
            ...previousState.formItems[fieldName],
            alerted: false
          }
        }
      }));
    }

    if (CommonHelper.isExist(this.state.formItems[fieldName].props) &&
        CommonHelper.isExist(this.state.formItems[fieldName].props.onChange))
    {
      this.state.formItems[fieldName].props.onChange(value);
    }
  };

  _generateReferenceMap = (formItems = []) =>
  {
    const referenceMap = {};

    for (let i = 0; i < formItems.length; i++)
    {
      referenceMap[formItems[i].fieldName] = React.createRef();
    }

    return referenceMap;
  };

  _generateItemMap = (formItems = []) =>
  {
    const itemMap = {};

    for (let i = 0; i < formItems.length; i++)
    {
      const currentItem = formItems[i];
      itemMap[currentItem.fieldName] = {
        ...currentItem,
        alerted: false
      };
    }

    return itemMap;
  };

  render ()
  {
    const generateForm = () =>
    {
      const result = [];
      const formItems = Object.values(this.state.formItems);

      for (let i = 0; i < formItems.length; i++)
      {
        const currentItem = formItems[i];
        result.push((
                      <div
                        key={currentItem.fieldName}
                        className={"form__item"}
                      >
                        {
                          CommonHelper.isExist(currentItem.label)
                          &&
                          (
                            <div className={"form__item-label"}>
                              {
                                currentItem.label + (currentItem.config.required
                                                     ? " *"
                                                     : "")
                              }
                            </div>
                          )
                        }

                        <currentItem.component
                          key={currentItem.fieldName}
                          {...currentItem.props}
                          onChange={(value) => this.onItemChange(currentItem.fieldName, value)}
                          className={(currentItem.alerted
                                      ? "form__alerted"
                                      : "") + " " + ((CommonHelper.isExist(currentItem.props) && CommonHelper.isExist(currentItem.props.className))
                                                     ? currentItem.props.className
                                                     : "")}
                          ref={this.state.references[currentItem.fieldName]}
                        />

                        {
                          currentItem.alerted
                          &&
                          (
                            <div className={"form__message-wrapper"}>
                              <div className={"form__message"}>
                                {
                                  currentItem.config.message
                                }
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ));
      }

      return result;
    };

    return (
      <div className={"form"}>
        {
          generateForm()
        }
      </div>
    );
  }
}

export default Form;