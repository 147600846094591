import React, {Component} from "react";
import CommonHelper from "../../libs/CommonHelper";

class Button extends Component
{
  onClick = (event) =>
  {
    if (CommonHelper.isExist(this.props.onClick))
    {
      this.props.onClick(event)
    }
  }

  render ()
  {
    return (
      <button
        className={"button"}
        onClick={this.onClick}
      >
        {
          this.props.text
        }
      </button>
    );
  }
}

export default Button