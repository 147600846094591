import {Section, SectionHeader, Text, Header, SubHeader, UnorderedList} from "../generator/Generator";

export default (
  <div className={"privacy-policy"}>
    <Section>
      <Header text={"PRIVACY POLICY"} />

      <Text text={"SGL International Legal Consulting (‘SGL’) is committed to respecting the privacy\n" +
                  "of all visitors to its website and of its clients and contacts. Please read the following\n" +
                  "privacy policy to understand how we use and protect the personal information that\n" +
                  "you provide to us or that we obtain or hold about you and to understand what your\n" +
                  "rights are in relation to personal information that we hold. A ‘data controller’ is a\n" +
                  "person or organisation who alone or jointly determines the purposes for which, and\n" +
                  "the manner in which, any personal data is, or is likely to be, processed. This policy\n" +
                  "is issued on behalf of SGL as data controller."} />
    </Section>

    <Section>
      <SectionHeader text={"WHAT INFORMATION DOES SGL PROCESS?"} />

      <SubHeader text={"From our general website"} />

      <Text text={"You may choose to provide personal information to us when you register with the website e.g.\n" +
                  "to register for an event, sign up for a newsletter or ask us to contact you. You may be asked to\n" +
                  "provide certain information about yourself including your name, title, postal address,\n" +
                  "telephone number and/or email address."} />

      <SubHeader text={"Automatically"} />

      <Text text={"We may also collect information automatically about your usage of our websites using\n" +
                  "cookies and other technology. To that effect, we use your IP address (a series of numbers that\n" +
                  "identifies a computer on the internet) to collect, among other things, internet traffic data and\n" +
                  "data regarding your browser type and computer. If you do not want to receive cookies, you\n" +
                  "may reject them by using your browser settings provided they are not necessary for delivery\n" +
                  "of our website or services to visitors."} />

      <SubHeader text={"For legal work"} />

      <Text
        text={"If you seek an estimate of costs for legal work, are provided with free initial advice or become a client of the\n" +
              "firm, we will also need information to confirm your identity e.g., your date of birth, about your matter and\n" +
              "circumstances as well as contact information for you, your personnel, and other parties to the matter. The\n" +
              "information that we need will be explained to you by our lawyers and/or set out in our letter of engagement\n" +
              "depending on the nature of your matter but may include both personal data and special categories of data. You\n" +
              "may also provide us with additional information that you consider relevant as part of your instruction.\n" +
              ""} />

      <SubHeader text={"From third parties"} />

      <Text
        text={"We may also receive information about you (or your personnel) from third parties such as estate agents,\n" +
              "accountants, banks, surveyors, medical professionals, courts, regulatory bodies and other advisors and\n" +
              "specialists related to your matter. Our clients and matter contacts may also provide us information about you if\n" +
              "you are involved in a transaction or dispute with one of our clients or have a connection with them such as\n" +
              "being a tenant or employee of a client. Alternatively, you may provide us with information about you directly at\n" +
              "our request or otherwise."} />
    </Section>

    <Section className={"privacy-policy__section"}>
      <SectionHeader text={"HOW DOES SGL USE THIS INFORMATION?"} />

      <Text
        text={"We will use your information for the specific purpose(s) for which it has been provided to or collected by us\n" +
              "e.g:"} />

      <UnorderedList
        listItems={[
          "to provide information that you may request regarding the services that we offer e.g. whether we can assist with certain legal advice",
          "to contact you to introduce you to our expert lawyers",
          "to provide our clients with legal services including referring them to other specialist advisers both in the UK, Turkey and/or other countries",
          "to comply with our statutory and regulatory obligations",
          "to verify your identity and check any relevant background circumstances for anti-money laundering purposes and ‘know your client’ purposes",
          "to deal with your feedback, query or complaint",
          "to contact you for your views on our services"
        ]}
      />

      <Text
        text={"We also use your information to administer, support, improve and develop our business generally and to enforce our legal rights."} />

      <Text
        text={"We may also use the information that we collect about you for marketing and hospitality purposes e.g:"} />

      <UnorderedList
        listItems={[
          "to provide communications about us and other services we provide that may be of interest to you e.g. sending you newsletters",
          "to provide you with updates on relevant areas of law and practice",
          "to invite you for a meal or drinks or to a hospitality event"
        ]}
      />

      <Text
        text={"Information gathered through cookies and similar technologies are used to measure and analyse information on visits to our websites, to tailor the websites to make them better for visitors and to improve technical performance. We will not use the data to identify you personally or to make any decisions about you."} />

      <SubHeader text={"Processing grounds"} />

      <Text
        text={"We must have a lawful basis for processing your information; this will vary on the circumstances of how and why we have your information but typical examples include:"} />

      <UnorderedList
        listItems={[
          "the activities are within our legitimate interests as a law firm seeking to engage with and provide services to prospective and current clients and personnel e.g. to make our systems and procedures more efficient",
          "you have given consent for us to process your information e.g. in relation to certain marketing activities",
          "we are carrying out necessary steps in relation to a contract to which you are party or prior to you entering into a contract e.g. because you wish to instruct us to carry out legal services for you",
          "the processing is necessary for compliance with a legal obligation to which we are subject e.g. for us to certify your identity under our anti-money laundering requirements including carrying out electronic ID checks",
          "to protect your vital interests e.g. if you were unfortunate enough to fall ill or suffer an injury on our premises"
        ]}
      />

      <Text
        text={"If we process any special categories of information i.e. information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs or trade union membership, genetic data, processing of biometric data for the purpose of uniquely identifying individuals, health data, or data concerning your sex life or sexual orientation, we must have a further lawful basis for the processing. This may include:"} />

      <UnorderedList
        listItems={[
          "where you have given us your explicit consent to do so e.g. to cater for your medical or dietary needs at an event",
          "where the processing is necessary to protect your vital interests or someone else’s vital interests",
          "you have made the information public",
          "the processing being necessary for the establishment, exercise or defence of legal claims",
          "the processing being necessary for reasons of substantial public interest e.g. to undertake activities in relation to the prevention or detection of fraud or other unlawful or dishonest activities"
        ]}
      />

      <Text
        text={"If we process any information relating to your criminal convictions or offences, we will typically rely on one of the following lawful bases:"} />

      <UnorderedList
        listItems={[
          "preventing or detecting unlawful acts",
          "complying with our regulatory requirements in relation to unlawful acts or dishonesty",
          "dealing with suspicions of terrorist financing or money laundering",
          "where it is necessary for us to obtain legal advice or establish, exercise or defend legal rights"
        ]}
      />
    </Section>

    <Section>
      <SectionHeader text={"MARKETING"} />

      <Text
        text={"If you become a client of the firm, we may use your personal information to invite you to hospitality events or other selected events for clients and/or to send you information that we think may be of interest to you or your business. This is within our legitimate interests as a law firm to use your information in this way."} />
      <Text
        text={"We may also use personal information about our professional contacts to invite them to hospitality events or other selected events and/or to send them information that we think may be of interest to them or their business. This is also within our legitimate interests."} />
      <Text
        text={"You will be given an opportunity to tell us whether or not you wish to receive direct marketing materials and communications from us either at the time you submit or provide your details to us (primarily if you are a private individual, a sole trader or an unincorporated partnership) and/or within the communication itself."} />
      <Text
        text={"If you do not wish to receive any direct marketing material or communications after you submit or provide your details, please send us an e-mail (stating UNSUBSCRIBE in the heading of any email message) indicating if you do not wish to be contacted for one or more of these marketing purposes and/or via particular forms of communication e.g. email or telephone. Please note that the preferences that you state will override any registrations you or your organisation may have with the relevant preference organisations (such as the telephone preference services)."} />
    </Section>

    <Section>
      <SectionHeader text={"DISCLOSURE OF YOUR INFORMATION"} />

      <Text
        text={"Some of the information you provide to SGL may be transferred to, stored and processed by third-party organisations which process data for us and on our behalf. These third parties may be based (or store or process information) in the UK, Turkey or elsewhere including outside of the EEA. As with many law firms, these third parties may include third-party IT platforms (including cloud-based platforms), suppliers of administrative and support services, and suppliers of other specialist products."} />
      <Text
        text={"We may also transfer your information to other organisations or professional advisors with whom we are working on client matters or to whom we are referring you for additional or separate advice. If you agree to act as a referee for us in relation to other legal work for which we are tendering, we will only do this with your prior permission."} />
      <Text
        text={"We may share your information with service providers who assist us with our marketing and communications."} />
      <Text
        text={"We may also be obliged to disclose data under certain laws or by order of Court or other competent regulatory body, or may be permitted to disclose it under applicable data protection laws."} />
      <Text
        text={"We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy; please see section ‘Protection of your information’ below."} />
    </Section>

    <Section>
      <SectionHeader text={"PROTECTION OF YOUR INFORMATION"} />

      <Text
        text={"We have in place administrative, technical and physical measures on our website and internally designed to guard against and minimise the risk of loss, misuse or unauthorised processing or disclosure of the personal information that we hold."} />
      <Text
        text={"Where we transfer information to third parties to enable them to process it on our behalf, we ensure that the providers meet or exceed the relevant legal or regulatory requirements for transferring data to them and keeping it secure."} />
      <Text
        text={"We will also ensure that where information is transferred to a country or international organisation outside of the UK/EEA, we will comply with the relevant legal rules governing such transfers."} />
      <Text
        text={"We keep your personal information no longer than is necessary for the purpose for which it was collected. Information for clients is provided in our engagement letter and terms of business including details on how we keep secure personal data received from clients about their tenants, employees, third parties or similar individuals."} />
    </Section>

    <Section>
      <SectionHeader text={"YOUR RIGHTS"} />

      <Text
        text={"You have certain rights in relation to your personal information, although those rights will not apply in all cases or to all information that we hold about you. For example, we may need to continue to hold and process information to establish, exercise or defend our legal rights. We will tell you if this is the case when you contact us."} />
      <Text text={"You have the right to request that we:"} />

      <UnorderedList
        listItems={[
          "provide you with a copy of your personal information that we hold",
          "update your personal information where it is out-of-date or incorrect",
          "delete personal information that we hold",
          "restrict the way in which we process your information",
          "consider any valid objections to our processing of your personal information",
          "provide information you have given to us to a third-party provider of services (where our lawful basis for processing is consent or contract and where processing is automated)"
        ]}
      />

      <Text
        text={"We will respond to your request (including providing information on whether the rights apply in the particular circumstances) within the applicable statutory time period. If we are not sure of your identity, we may require you to provide further information in order for us to confirm who you are."} />
    </Section>

    <Section>
      <SectionHeader text={"COOKIES"} />

      <Text
        text={"Cookies, also known as browsers or tracking cookies, are small text files that are added to your computer when you visit a website. They help websites to perform certain functions eg to know who you are if you log into a restricted part of a website, for shopping carts, and for tracking purposes."} />
      <Text
        text={"Necessary Cookies: At SGL we use necessary cookies to enable us to operate our website. These cookies will be placed on your device shortly after arriving at our website and as you navigate its pages. You cannot opt out of the use of these cookies."} />
      <Text
        text={"Optional Cookies: We will ask for your permission to place certain cookies or other similar technologies on your device. You can withdraw any consent to the use of cookies or manage any other cookie preferences by using the privacy dashboard at the bottom of any page on this site and clicking the close arrow. It may be necessary to refresh the page for the updated settings to take effect."} />
      <Text text={"You can at any time change or withdraw your consent from the Cookie Declaration on our website."} />
    </Section>

    <Section>
      <SectionHeader text={"HOW TO CONTACT US"} />

      <Text
        text={"If you would like to get in touch to discuss this policy, how we use your personal information, to exercise your rights or to provide feedback or make a complaint about use of your information, please contact us from selin@sgllegal.co.uk"} />
    </Section>
  </div>
);