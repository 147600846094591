export default (
  <div className={"about-us__text-wrapper"}>
    <p className={"about-us__text"}>
      SGL is established by Selin Gok Isozu. She is a dual-qualified solicitor in both England & Wales and Turkey. She is a member of the Istanbul Bar Association with registration number 45456 and registered to the Roll of Solicitors in England & Wales with SRA number 7006134. She is also a licensed trademark attorney before the Turkish Patent Institution and a licensed mediator registered to the Turkish Mediators Registry.
    </p>

    <p className={"about-us__text"}>
      Selin obtained her bachelor’s degree in law from Bahcesehir University with a full scholarship in 2011. She also spent a year at the National and Kapodistrian University of Athens during her studies. She received her joint LLM degree with honors from Nottingham Law School, Nottingham Trent University in International Trade and Corporate Law. Selin was awarded the Paragon Law Prize for being the highest-ranked LL.M. student. Her thesis with high distinction was “Assessing the Practical Consequences for Joint Venture Deals of Conceptual Distinctions under Turkish and English Contract Laws”. She was also awarded the Association of Partnership Practitioners Prize.
    </p>

    <p className={"about-us__text"}>
      During her career, Selin has been actively providing high-quality legal assistance to its international clients with particular focus areas including commercial contract law, investment & start-up consultancy, corporate restructuring, trademark, and data protection laws.
    </p>
  </div>
);
