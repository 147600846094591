import {createStore, combineReducers} from "redux";
import {persistStore, persistReducer, createTransform} from "redux-persist";
import storage from "redux-persist/lib/storage";
import CryptoJS from "crypto-js";

import appReducer from "../store/reducers/appReducer";

import {storeEncriptionKey} from "./app.config";

import CommonHelper from "../libs/CommonHelper";

const encriptor = createTransform(
  (inboundState, key) =>
  {
    if (!CommonHelper.isExist(inboundState))
    {
      return inboundState;
    }

    let encriptedInboundState = CryptoJS.AES.encrypt(JSON.stringify(inboundState), storeEncriptionKey);
    encriptedInboundState = encriptedInboundState.toString();

    return encriptedInboundState;
  },
  (outboundState, key) =>
  {
    if (!CommonHelper.isExist(outboundState))
    {
      return outboundState;
    }

    let decryptedOutboundState = CryptoJS.AES.decrypt(outboundState, storeEncriptionKey);
    decryptedOutboundState = decryptedOutboundState.toString(CryptoJS.enc.Utf8);
    decryptedOutboundState = JSON.parse(decryptedOutboundState);

    return decryptedOutboundState;
  }
);

const highLevelReducer = combineReducers({
                                           appReducer: persistReducer(
                                             {
                                               key: "appReducer",
                                               whitelist: ["language", "user", "cookieAccepted"],
                                               storage: storage,
                                               transforms: [encriptor]
                                             },
                                             appReducer
                                           )
                                         });

const persistedHighLevelReducer = persistReducer(
  {
    key: "root",
    blacklist: ["appReducer"],
    storage: storage
  },
  highLevelReducer
);

const configStore = () =>
{
  const store = createStore(persistedHighLevelReducer);
  const persistor = persistStore(store);

  return {
    store: store,
    persistor: persistor
  };
};

export default configStore;