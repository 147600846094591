export const errorRepoTR = {
  content_required: "İçerik gerekli bir alan.",

  email_required: "Email gerekli bir alan.",

  first_name_required: "İsim gerekli bir alan.",

  last_name_required: "Soyisim gerekli bir alan.",

  phone_required: "Telefon gerekli bir alan.",

  subject_required: "Konu gerekli bir alan.",

  there_is_a_problem_with_getting_posts: "Gönderileri getirirken bir sorun oluştu. Lütfen daha sonra tekrar deneyin.",
  there_is_a_problem_with_getting_the_post: "Gönderiyi getirirken bir sorun oluştu.",

  we_could_not_send_your_thoughts: "Düşüncelerini şu an gönderemedik. Lütfen daha sonra tekrar dene.",

  your_contact_request_could_not_be_registered: "İletişim isteğinizi kayıt edemedik."
};