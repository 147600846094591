export const wordRepoEN = {
  about_founder: "About the Founder",
  about_sgl: "About SGL",
  about_us: "About us",
  accept: "Accept",
  address: "Address",
  emailPhone: "Email/Phone",

  coming_soon: "Coming soon...",
  commitment: "Commitment",
  contact: "Contact",
  content: "Content",

  disclaimer: "Disclaimer",

  email: "Email",

  first_name: "First name",

  insights: "Insights",
  integrity_and_trust: "Integrity & Trust",

  last_name: "Last name",

  menu: "Menu",

  phone: "Phone",
  phone_placeholder: "+44 7xxx xxxxxx",
  privacy_policy: "Privacy policy",

  quality: "Quality",

  responsiveness: "Responsiveness",

  send: "Send",
  services: "Services",
  single_point_of_contact: "Single point of contact",
  subject: "Subject",
  submit: "Submit",

  upload: "Upload"
};
