export default (
  <div className={"about-us__text-wrapper"}>
    <p className={"about-us__text"}>
      Being a dual-qualified solicitor in both England & Wales and Turkey, SGL provides its clients with the best-in-class legal assistance in Turkey-UK-related business affairs and personal legal issues.
    </p>

    <p className={"about-us__text"}>
      SGL offers a range of legal consultancy services to individuals and businesses integrating legal services between London and Turkey almost like a bridge for the clients in the UK to the Turkish legal system. As it might be troublesome for the clients to deal with a foreign legal system in a foreign language, SGL offers bilingual legal services in Turkish law in the UK to eliminate the difficulties that the clients may face and solve their problems on their behalf delivering trusted, practical, and effective advice.
    </p>

    <p className={"about-us__text"}>
      SGL, with its Turkish solicitors in London, provides legal services on matters concerning English and Turkish Laws maintaining ethical and reliable standards. SGL offers effective legal security to companies doing business or British individuals having personal legal affairs like inheritance or divorce proceedings in Turkey or as well as those seeking to invest in or do business in the UK from Turkey.
    </p>

    <p className={"about-us__text"}>
      SGL offers expert counsel on both English and Turkish laws while having a reliable legal presence in London and Turkey. SGL works in cooperation with fellow Turkish law firms based in Istanbul, Fethiye, Kalkan, and Bursa to provide the most comprehensive and quality-assured services to its clients, especially through her sister office CY Attorneys. SGL also assists its clients through trusted real estate agents to find the best property to buy in Turkey and assists with notarial services and apostille.
    </p>
  </div>
);
