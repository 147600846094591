import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import _ from "lodash";

import i18nRepo from "../../../i18n-repo/i18nRepo";

import {
  realEstateLaw,
  intellectualProperty,
  familyLaw,
  inheritanceLaw,
  corporateCommercial,
  contractLaw,
  turkishCitizenship,
  notarialAndLegal,
  personalInjury,
  dataProtectionLaw,
  employmentLaw,
  debtRecovery,
  expertOpinions
} from "./contents/Contents";

import ArrowButton from "../../ui-kit/ArrowButton";

import {Pins} from "../../../assets";

import "../../../style/classes.scss";

const PAGES = {
  MENU: "MENU",
  CONTENT: "CONTENT"
};

const SERVICES = {
  "contract-law": {
    key: "contract-law",
    header: "Contract Law",
    description: "We are highly experienced in drafting, reviewing, negotiating, amending, and executing all types of international contracts.",
    content: contractLaw
  },
  "corporate-commercial": {
    key: "corporate-commercial",
    header: "Corporate & Commercial Law and Investment",
    description: "We provide full service in corporate and commercial law with a track record in delivering flexible and creative solutions.",
    content: corporateCommercial
  },
  "data-protection-law": {
    key: "data-protection-law",
    header: "Data Protection Law",
    description: "We can help you deliver on your global strategies while navigating the local differences which complicate the data protection arena.",
    content: dataProtectionLaw
  },
  "debt-recovery": {
    key: "debt-recovery",
    header: "Debt recovery and Enforcement Law",
    description: "We assist and support our clients to collect their receivables from companies or individuals in the most effective way.",
    content: debtRecovery
  },
  "employment-law": {
    key: "employment-law",
    header: "Employment Law",
    description: "We will provide you with accurate, well-researched, end-to-end advice whether you are an employer or an employee.",
    content: employmentLaw
  },
  "expert-options": {
    key: "expert-options",
    header: "Expert Opinions on Turkish Law",
    description: "We provide independent expert legal opinions on Turkish law to be used in litigation or alternative dispute resolution.",
    content: expertOpinions
  },
  "family-law": {
    key: "family-law",
    header: "Family Law",
    description: "We understand your concerns and promise to make family law issues as the least trouble-free as possible.",
    content: familyLaw
  },
  "inheritance-law": {
    key: "inheritance-law",
    header: "Inheritance Law",
    description: "We are ready to provide you with tailor-made assistance in all types of Turkish Inheritance Law and probate issues.",
    content: inheritanceLaw
  },
  "intellectual-property": {
    key: "intellectual-property",
    header: "Intellectual Property Law",
    description: "We provide strategic, sensible, and practical advice to ensure that your intellectual property rights are fully protected.",
    content: intellectualProperty
  },
  "notarial-and-legal": {
    key: "notarial-and-legal",
    header: "Notarial and Legal Translation Services",
    description: "We assist in your notarial issues and translate complex legal documents from English to Turkish and from Turkish to English.",
    content: notarialAndLegal
  },
  "personal-injury": {
    key: "personal-injury",
    header: "Personal Injury & Medical Negligence",
    description: "We are highly specialized in hotel accidents, slip, trips, and falls, road traffic accidents, and medical negligence issues.",
    content: personalInjury
  },
  "real-estate-law": {
    key: "real-estate-law",
    header: "Real Estate Law and Property Conveyancing",
    description: "We make purchasing and selling a property in Turkey a smooth and completely safe process with the help of our strategic alliances.",
    content: realEstateLaw
  },
  "turkish-citizenship": {
    key: "turkish-citizenship",
    header: "Turkish Citizenship and Turkish Immigration Law",
    description: "We have been advising businesses and individuals on all aspects of Turkish immigration and nationality law for years.",
    content: turkishCitizenship
  }
};

class Services extends Component
{
  constructor (props)
  {
    super(props);

    this.state = {
      currentPage: PAGES.MENU,
      isInitial: true,
      selectedKey: null
    };
  }

  componentDidMount ()
  {
    if (_.isEqual(this.props.language, i18nRepo.languages.tr))
    {
      this.props.history.replace("/");
    }
  }

  shouldComponentUpdate (nextProps, nextState, nextContext)
  {
    if (_.isEqual(nextProps.language, i18nRepo.languages.tr))
    {
      this.props.history.replace("/");
    }

    return true;
  }

  onClickMenu = (key) =>
  {
    this.setState((previousState, props) => ({
      currentPage: PAGES.CONTENT,
      isInitial: false,
      selectedKey: key
    }));
  };

  onClickContent = () =>
  {
    this.setState((previousState, props) => ({
      currentPage: PAGES.MENU,
      isInitial: false,
      selectedKey: null
    }));
  };

  render ()
  {
    const {windowPreferences} = this.props;

    const prepareMenu = () =>
    {
      const serviceOptions = Object.values(SERVICES);

      const result = [];
      for (let i = 0; i < serviceOptions.length; i++)
      {
        const currentOption = serviceOptions[i];

        result.push((
                      <div
                        key={currentOption.key}
                        className={"service__menu-item"}
                      >
                        <div className={"service__menu-item-content-wrapper"}>
                          <div className={"service__menu-item-header"}>
                            {
                              currentOption.header
                            }
                          </div>

                          <div className={"service__menu-item-description"}>
                            {
                              currentOption.description
                            }
                          </div>
                        </div>

                        <ArrowButton
                          position={"right"}
                          onClick={() => this.onClickMenu(currentOption.key)}
                        />
                      </div>
                    ));
      }

      return result;
    };

    return (
      <div className={"initial-page service"}>
        {
          _.isEqual(this.state.currentPage, PAGES.MENU)
          &&
          (
            <div
              className={"service__menu-container " + (!this.state.isInitial
                                                       ? "service__menu-animation"
                                                       : "")}
            >
              <div className={"service__menu-wrapper"}>
                {
                  prepareMenu()
                }
              </div>
            </div>
          )
        }

        {
          !windowPreferences.isTablet
          &&
          (
            <div
              key={this.state.currentPage}
              className={"service__panel " + (_.isEqual(PAGES.MENU, this.state.currentPage)
                                              ?
                                              (
                                                this.state.isInitial
                                                ? "service__panel__right"
                                                : "service__panel__animate-right"
                                              )
                                              :
                                              (
                                                this.state.isInitial
                                                ? "service__panel__left"
                                                : "service__panel__animate-left"
                                              ))}
            >
              <img
                src={Pins}
                className={"service__image"}
              />
            </div>

          )
        }

        {
          _.isEqual(this.state.currentPage, PAGES.CONTENT)
          &&
          (
            <div
              className={"service__content " + (!this.state.isInitial
                                                ? "service__content-animation"
                                                : "")}
            >
              <div className={"service__content-header"}>
                <ArrowButton
                  key={"content-back"}
                  position={"left"}
                  onClick={this.onClickContent}
                />
              </div>

              <div className={"service__content-wrapper"}>
                {
                  SERVICES[this.state.selectedKey].content
                }
              </div>
              {

              }
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.appReducer.language,
  windowPreferences: state.appReducer.windowPreferences
});

export default withRouter(connect(mapStateToProps)(Services));
