import {
  SET_LOADING,
  SET_LANGUAGE,
  SET_WINDOW_STATUS,
  SET_COOKIE_ACCEPTED,
  SET_SCROLL_DISABLED
} from "../actionTypes";

export const setCookieAccepted = (isAccepted) =>
{
  return {
    type: SET_COOKIE_ACCEPTED,
    payload: {
      cookieAccepted: isAccepted
    }
  };
};

export const setLanguage = (language) =>
{
  return {
    type: SET_LANGUAGE,
    payload: {
      language: language
    }
  };
};

export const setWindowStatus = (windowPreferences) =>
{
  return {
    type: SET_WINDOW_STATUS,
    payload: {
      windowPreferences: windowPreferences
    }
  };
};

export const setLoading = (loading) =>
{
  return {
    type: SET_LOADING,
    payload: {
      loading: loading
    }
  };
};

export const setScrollDisabled = (scrollDisabled) =>
{
  return {
    type: SET_SCROLL_DISABLED,
    payload: {
      scrollDisabled: scrollDisabled
    }
  };
};