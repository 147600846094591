import React, {Component} from "react";

import {SglIcon} from "../../assets/index";

import "../../style/classes.scss";

class Spinner extends Component
{
  render ()
  {
    return (
      <div className={"spinner-container"}>
        <SglIcon className={"spinner"} />
      </div>
    );
  }
}

export default Spinner;