export default (
  <div className={"disclaimer"}>
    <div className={"disclaimer__header"}>
      HUKUKİ UYARI
    </div>

    <div className={"disclaimer__text"}>
      İşbu internet sitesi yalnızca bilgi amaçlı olup, bu internet sitesinde bulunan hiçbir bilgi hukuki
      tavsiye ya da reklam amacına yönelik değildir. İşbu internet sitesi aracılığı ile bilgi
      görüntülenmesi ve edinilmesi; soru ve talep formu doldurulması ve gönderilmesi SGL ile
      hiçbir hukuki ilişki ve/veya avukat-müvekkil ilişkisi doğurmaz. SGL işbu internet sitesinde
      sunulan hiçbir bilginin içeriği, güncelliği ya da doğruluğu konusunda bir garanti
      vermemektedir ve ziyaretçilerin bu internet sitesinde yer alan bilgilere dayanarak
      gerçekleştirecekleri hiçbir eylemden ötürü sorumluluk kabul etmemektedir.
    </div>
    <div className={"disclaimer__text"}>
      İşbu internet sitesi ve içeriğinde yer alan tüm bilgi ve diğer materyal SGL ’ye aittir.
    </div>
  </div>
);