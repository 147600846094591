import React, {Component} from "react";

import CommonHelper from "../../libs/CommonHelper";

import LandingPage from "./LandingPage";

import Header from "../elements/Header";
import Footer from "../elements/Footer";

import Layout from "../ui-kit/Layout";

import "../../style/classes.scss";
import MessageGate from "../ui-kit/MessageGate";

class Home extends Component
{
  render ()
  {
    return (
      <Layout
        header={<Header />}
        footer={<Footer />}
      >
        <MessageGate key={"home"}>
          {
            CommonHelper.isExist(this.props.children)
            ? this.props.children
            : <LandingPage />
          }
        </MessageGate>

      </Layout>
    );
  };
}

export default Home;