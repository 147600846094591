export const ADDRESS = {
  detail1: "SW15 1QL",
  city: "Putney, London",
  position: {lat: 51.46523005275942, lng: -0.22362791710097546}
};

export const EMAIL_PHONE = {
  email: "selin@sgllegal.co.uk",
  phone: "+44 7835 758417"
};
