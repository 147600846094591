import Service from "./Service";


const route = "/postContactData";

class PostContactService extends Service
{
  constructor ()
  {
    super(route, {
      defaultRoutes: [
        Service.ROUTE_MAP.CREATE
      ],
      authenticated: false
    });
  }
}

export default PostContactService;