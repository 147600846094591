export const UnorderedList = (props) =>
{
  const result = [];

  for (let i = 0; i < props.listItems.length; i++)
  {
    const currentItem = props.listItems[i];

    result.push((
                  <li
                    key={i}
                    className={"privacy-policy__unordered-list-item"}
                  >
                    {
                      currentItem
                    }
                  </li>
                ));
  }

  return (
    <ul
      className={"privacy-policy__unordered-list"}
    >
      {
        result
      }
    </ul>
  )
};

export const Section = (props) =>
{
  return (
    <div className={"privacy-policy__section"}>
      {
        props.children
      }
    </div>
  )
}

export const Header = (props) =>
{
  return (
    <div className={"privacy-policy__header"}>
      {
        props.text
      }
    </div>
  )
}

export const SectionHeader = (props) =>
{
  return (
    <div className={"privacy-policy__section-header"}>
      {
        props.text
      }
    </div>
  )
}

export const SubHeader = (props) =>
{
  return (
    <div className={"privacy-policy__sub-header"}>
      {
        props.text
      }
    </div>
  )
}

export const Text = (props) =>
{
  return (
    <div className={"privacy-policy__text"}>
      {
        props.text
      }
    </div>
  )
}