import React from "react";
import _ from "lodash";

const prepareHeader = (text) =>
{
  return (
    <div className={"service__content-text-header"}>
      {
        text
      }
    </div>
  );
};

const prepareText = (text, highlight = false) =>
{
  return (
    <div className={"service__content-text " + (highlight
                                                ? "service__content-text-highlight"
                                                : "")}>
      {
        text
      }
    </div>
  );
};

const prepareUnorderedList = (items) =>
{
  return (
    <ul className={"service__unordered-list"}>
      {
        items.map((item, index) => (
          <li
            key={index}
            className={"service__unordered-list-item"}
          >
            {
              _.isString(item)
              ? item
              :
              (
                <React.Fragment>
                  <span className={"service__unordered-list-item__header"}>
                    {
                      item.header
                    }
                  </span>

                  {
                    item.text
                  }
                </React.Fragment>
              )
            }
          </li>
        ))
      }
    </ul>
  );
};

export const realEstateLaw = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Real Estate Law and Property Conveyancing")
    }

    {
      prepareText("Purchasing Property in Turkey and real estate acquisition in Turkey for foreigners is an issue" +
                  " which we strictly advise to be followed by a lawyer in order not to lose any time or money." +
                  " There are certain mandatory provisions of the law for a real estate transaction to be legally" +
                  " binding. We also assist you to find the best and real priced property in especially Fethiye," +
                  " Kalkan, Kas, Gocek, Antalya, Istanbul, Izmir, and all cities of Turkey through our reliable and" +
                  " trustworthy real estate agent and contractor alliances and evaluation experts. You will have" +
                  " the comfort of having a single point of contact who will find you the suitable residential home" +
                  " and commercial property for purchase or rent between your price range and carry out all" +
                  " procedures on behalf of you. By obtaining an official independent expert valuation report" +
                  " from licensed evaluation experts in Turkey, we eliminate the risks of overpricing.")
    }

    {
      prepareText("Your English-speaking London-based Turkish property lawyer provides complete end-to-end" +
                  " conveyancing service, conducts due diligence, offers you the most efficient and feasible legal" +
                  " assistance providing security and trust, and reports all information and documents" +
                  " transparently in English in due time. Our property team also advises on buy-to-let" +
                  " arrangements, routine property management matters, drafting real estate contracts, mortgages," +
                  " gifting of properties, and transfers of equity. We make purchasing and selling a property in" +
                  " Turkey and invest in Turkey a smooth and safe process with a reliable London-based Turkish" +
                  " real estate lawyer by your side.")
    }

    {
      prepareText("We also provide assistance in leasing summer houses and holiday houses in Fethiye, Kalkan," +
                  " Kas, Istanbul, and other cities of Turkey in a cost-efficient, hassle-free and seamless way with" +
                  " no further surprises.")
    }

    {
      prepareText("Real estate litigation services in Turkey such as actions for land registration and annulment of" +
                  "registration, action for prevention of confiscation, action for the elimination of joint" +
                  "ownership, and action for evacuation are also in our expertise.")
    }

    {
      prepareText("We can also provide reliable legal assistance if you would like to buy or rent a boat or yacht" +
                  "in Fethiye region.")
    }

    {
      prepareText("Get in touch with us today to have assistance in Turkish property law.", true)
    }
  </div>
);

export const intellectualProperty = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Intellectual Property Law")
    }

    {
      prepareText("Being a licensed Trademark lawyer in Turkey, we understand the importance of protecting your intellectual property rights and the reputation of your organization from inevitable threats from competitors. This may be achieved by making respective registrations of your trademarks, patents, designs, and copyrights and drafting proper intellectual property licensing agreements. We are highly experienced in the registration of all types of IP rights before the Turkish Patent and Trademark Office. After registering your trademark in Turkey, we monitor the Turkish Trademark Bulletin regularly to alert you on time of possible trademark infringements and take appropriate steps. Trademark and patent litigation in Turkey is one of our strong suits, we offer high-quality services in infringement actions, preliminary injunctions, and injunctive relief, actions on the merits, enforcement, cancellation, invalidity actions, and counterclaims for revocation as well as oppositions. We also prepare and file customs applications to track the possible infringers and protect your IP rights. If you would like to register your IP rights in the UK, we will also assist you through our strategic partners experienced in trademark and patent rights in London.")
    }

    {
      prepareText("Assisting in Turkish copyright law and drafting and negotiation of especially film director and screenplay agreements, actor services agreement, songwriter, and composer's agreements for film music, location agreements is within our expertise.")
    }

    {
      prepareText("Get in touch with us today to ensure that your IP rights are protected in Turkey or the UK.", true)
    }
  </div>
);

export const familyLaw = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Family Law")
    }

    {
      prepareText("If you have a Turkish partner and you would like to divorce, we understand your concerns having to deal with family law issues in a foreign jurisdiction, but our experienced London based Turkish family and divorce lawyers promise to try their utmost best to make this process as the least trouble-free as possible. Our services for Turkish Family Law are rendered in English and include divorce proceedings, application for recognition and enforcement of divorce decisions in Turkey, making claims for material and immaterial damages in uncontested or contested divorce cases, drafting prenuptial agreements, adoption, applying for court measures against domestic violence, or applying for child support and custody. We successfully assist clients who live in the UK but have children or divorce matters in Turkey with our reliable London-based Turkish family lawyers. We are also highly experienced in the recognition and enforcement of foreign judgments in Turkey.")
    }

    {
      prepareText("Get in touch with us today to have assistance in Turkish family law.", true)
    }
  </div>
);

export const inheritanceLaw = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Inheritance Law")
    }

    {
      prepareText("If you will inherit a property in Turkey if you are the heir of a Turkish national, our Turkish Inheritance Law lawyers based in the UK are ready to provide you with tailor-made assistance in all types of Turkish Inheritance Law and probate issues such as obtaining a certificate of inheritance from Turkey, opening a succession, taking actions for the administration of an estate, appointment of substitute heir and appointment of a posterior heir, contract of renunciation of the inheritance, disinheritance, action for annulment of testamentary disposition, elimination of joint ownership due to the inheritance, disclaimer of inheritance, contracts over hereditary share.")
    }

    {
      prepareText("Our services also include will and testament drafting under Turkish law and execution and transformation of assets during lifetime.")
    }

    {
      prepareText("Get in touch with us today if you have any issues regarding the Turkish Inheritance Law.", true)
    }
  </div>
);

export const corporateCommercial = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Corporate & Commercial Law and Investment")
    }

    {
      prepareText("We provide legal assistance at all stages of a corporation’s life cycle, from formation to liquidation. If you would like to invest in and set up a company in Turkey, we can assure you that our experienced Corporate and Commercial Lawyers in Turkey will assist you to find the most effective investment vehicle bringing a commercial outlook and a real market perspective. We also conduct a regulatory search for licensing purposes and undertake all to make sure all legal requirements are met. We provide full service with our reliable key consultants in accounting, tax, and arranging company premises for the clients who would like to invest in and doing business in Turkey.")
    }

    {
      prepareText("Our Turkish Corporate and Commercial law services include incorporation of companies in Turkey, setting up of subsidiaries, branches, or liaison office of foreign companies in Turkey; drafting joint venture agreements, board and general assembly resolutions, shareholder agreements, share transfer agreements, signature circulars, conducting due diligence, mergers & acquisitions, corporate restructuring, free zone company set up services, registration to Foreign Investment Registration System (e-tuys), company liquidation, etc. We conduct all required legal procedures at the trade registries or notaries for all kinds of company secretarial issues. We also assist start-ups who would like to enter the Turkish market with tailor-made result-oriented legal strategies for their businesses.")
    }

    {
      prepareText("If you are a Turkish citizen and would like to invest in the UK or set up a company in London, we will also assist you through our strategic partners who are highly experienced in English Corporate Law.")
    }

    {
      prepareText("Get in touch with us today to if you would like to invest in or doing business in Turkey or UK.", true)
    }
  </div>
);

export const contractLaw = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Contract Law")
    }

    {
      prepareText("We advise our clients in Turkey and the clients doing business between Turkey and the UK on strategically significant agreements and transactions. Our business-minded approach enables us to guide you through the entire lifecycle of your business, providing practical legal solutions to practical business challenges. Our Turkish contract lawyers based in London are highly experienced in drafting, reviewing, negotiating, amending, and executing all types of contracts of the companies doing business between Turkey and the UK, including but not limited to the agreements for sale/supply of goods, standard terms and conditions of supply and purchase, marketing, advertising and promotion agreements, representation, agency, distributorship and franchise arrangements, joint ventures, co-operation, and collaboration agreements, strategic partnerships, procurement, and outsourcing, licensing, research, development and exploitation agreements, confidentiality agreements, etc. We work jointly with clients to generate exceptional contracting tactics, to make sure these strategies reflect the commercial drives and regulatory pressures facing each business.")
    }

    {
      prepareText("Get in touch with us today if you need a contract drafted or reviewed in Turkish law.", true)
    }
  </div>
);

export const turkishCitizenship = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Turkish Citizenship and Turkish Immigration Law")
    }

    {
      prepareText("We are exceptionally experienced in the applications for Turkish citizenship by investment and other ways. We provide high-quality and full Turkish citizenship law services such as introducing clients to reliable real estate agents, developers, and valuation companies, conducting property due diligence and acquisitions, filing, monitoring, and finalizing citizenship applications, opening bank accounts, obtaining potential tax numbers, applying for, and finalizing Turkish identity cards and passports, etc. We also assist you to find the best and real priced property in especially Istanbul, Fethiye, Kalkan, Kas, Gocek, Antalya, and Izmir through our trustworthy alliances.")
    }

    {
      prepareText("Our Turkish citizenship lawyers based in London will ensure that the entire process will be managed seamlessly and reliably from a single point of contact. Foreigners who satisfy at least one of the below-noted investment requirements may be granted citizenship, subject to the approval and an administrative decision of the President:")
    }

    {
      prepareUnorderedList([
                             "Foreigners who make a fixed capital investment amounting to a minimum of USD 500.000 or equivalent foreign currency or equivalent amount of Turkish Lira in a Turkish corporate entity that is determined by the Ministry of Industry and Technology.",
                             "Foreigners who purchase a real estate property with a minimum value of USD 250.000 or equivalent foreign currency or equivalent amount of Turkish Liras and attached an annotation of no-sale for at least three (3) years at the relevant title registry; or foreigners who purchase a real estate property which has property ownership or a construction servitude on it, with a preliminary sale contract executed before a notary public and at least USD 250,000 or amount of foreign currency or Turkish Liras is paid in advance and such notarized preliminary sale contract shall be recorded at the relevant title registry with the undertaking not to transfer or assign the agreement for three (3) years that is determined by the Ministry of Environment and Urbanization.",
                             "Foreigners generate employment for at least 50 individuals that are determined by the Ministry of Family, Labour, and Urbanization.",
                             "Foreigners who deposit a minimum of USD 500.000 or equivalent foreign currency or equivalent amount of Turkish Lira to banks that operate in Turkey with the condition to keep such deposit in the relevant bank for at least three (3) years that is determined by the Banking Regulation and Supervision Agency.",
                             "Foreigners who purchase state debt instruments with the value of a minimum of USD 500.000 or equivalent foreign currency or equivalent amount of Turkish Lira and keep in reserve for at least three (3) years that is determined by the Ministry of Treasury and Finance.",
                             "Foreigners who purchase real estate participation fund share and capital investment trusts fund to share with a minimum value of USD 500.000 or equivalent amount of foreign currency or Turkish Liras on the condition to keep those in reserve for at least three (3) years that is determined by the Capital Market Board."
                           ])
    }

    {
      prepareText("There are other ways for acquiring Turkish citizenship even if you do not fulfil one of the above-listed criteria. Please contact us for more information.")
    }

    {
      prepareText("We also have extensive experience in working with English expats and companies in Turkey with their Turkish immigration law issues. We offer our corporate and individual clients assistance for obtaining work permits and all types of residence permits in Turkey, transfer of foreign driving licenses to Turkish, and all kinds of Turkish immigration law issues.")
    }

    {
      prepareText("Get in touch with us today if you need Turkish Citizenship or Turkish Immigration Law services.", true)
    }
  </div>
);

export const notarialAndLegal = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Notarial and Legal Translation Services")
    }

    {
      prepareText("If you need a document such as powers of attorney, affidavits, declarations, copy passports, education, and other certificates, travel consents, documents relating to the overseas property or foreign inheritances, marriage documents, and civil status documents, etc. that needs to be notarized and apostilled in the UK or Turkey, we provide assistance in notarial services in both countries for both companies and private clients. We ensure that the document is drafted in both English and Turkish, notarized, and legalized in accordance with the applicable law through the notaries that we work in collaboration with. Every country has its own requirements as to how legal documents are prepared, executed, and certified. We are professionally qualified to advise on the varying formalities involved. If a document is notarized in the United Kingdom and is intended for use in Turkey or it is notarized in Turkey and is intended for use in the UK, it would need either an apostille certification or consulate legalization. We provide apostille services to assist you in acquiring official UK Apostille Certificates and Turkish Apostille Certificates. We also advise on any issues regarding the Turkish Embassy in London.")
    }

    {
      prepareText("Translation of complex legal documents from English to Turkish and from Turkish to English is also among our expertise. We will undertake any kinds of legal document translation and review work into and out of Turkish. Our experience and knowledge in this field enable us to provide the highest quality Turkish legal translation services to private and corporate clients, and legal firms.")
    }

    {
      prepareText("Get in touch with us today if you need notarization or legalization or Turkish legal translation services in Turkey or the UK.", true)
    }
  </div>
);

export const personalInjury = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Personal Injury & Medical Negligence")
    }

    {
      prepareText("We completely understand your frustration if you faced a personal injury in a foreign country where you will also face the problems of navigating through a foreign legal system to access compensation and overcoming a language barrier. We can help you with Turkish personal injury compensation claims so you can focus on your recovery. Although Turkey has professional health and tourism services, sometimes injuries on holiday or medical negligence may occur. Examples of the types of accidents and illnesses that can occur include:")
    }

    {
      prepareUnorderedList([
                             {
                               header: "Hotel/resort accidents, slip, trips, and falls in Turkey: ",
                               text: "When you are on holiday in Turkey, you may have slipped, tripped, and fell injuring yourself in a hotel, resort or you may have food poisoning in a restaurant."
                             },
                             {
                               header: "Road traffic accidents in Turkey: ",
                               text: "Either you live in Turkey or you were injured in Turkey in road traffic while you were on holiday without your fault, our experienced Turkish road traffic accident personal injury lawyers in London will be with you each step of the way and work hard to get you the compensation you deserve."
                             },
                             {
                               header: "Medical Negligence in Turkey: ",
                               text: "Many British nationals travel to Turkey both for dental operations such as implants and aesthetic and cosmetic surgery treatments. If something goes wrong and leads to a dental or medical negligence claim, our experienced Turkish medical negligence lawyers in London will support you through the process of bringing a claim to obtain much-needed compensation."
                             }
                           ])
    }

    {
      prepareText("Get in touch with us today ASAP if you faced a personal injury or medical negligence in Turkey.", true)
    }
  </div>
);

export const dataProtectionLaw = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Data Protection Law")
    }

    {
      prepareText("Our Turkish Data Protection Lawyers in London has undertaken various data privacy projects for multinational companies in Turkey and ensured compliance with the Turkish Data Protection Law. We assist clients with data protection law compliance, which includes the performance of audits, the preparation of registers before the Turkish Data Protection Authority, and the review and update of relevant documentation such as HR documents, contracts, etc. We provide tailor-made DPO (Data Protection Officer) services and training sessions both in English and Turkish.")
    }

    {
      prepareText("If you have any data processing activity in Turkey or regarding Turkish residents or nationals, we can help you deliver on your global strategies and objectives while navigating the several local differences which complicate the data protection arena. We also provide GDPR compliance consultancy services to our clients through our professional collaborative offices in London.")
    }

    {
      prepareText("Get in touch with us today if you need assistance regarding Data Protection Law in Turkey or UK.", true)
    }
  </div>
);

export const employmentLaw = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Employment Law")
    }

    {
      prepareText("We advise both expats working in Turkey and British companies located in Turkey on all types of employment challenges and opportunities. We develop and implement policies minimizing the risk of litigation in employment law and provide an accurate, well-researched, end-to-end solution for any Turkish employment law matter we undertake. Being a specialized licensed mediator in Turkish Labour Law registered to the Turkish Mediators Registry, we resolve the employment disputes in a much more time and cost-effective manner through mediation.")
    }

    {
      prepareText("Our Turkish employment law experts advise on many issues including but not limited to:")
    }

    {
      prepareUnorderedList([
                             "Employment contracts",
                             "Dismissals and settlement procedures",
                             "Working time arrangements",
                             "Employment policies and handbooks",
                             "Health and safety in the workplace",
                             "Employment discrimination and harassment",
                             "Employee benefits and incentives, including executive compensation and benefits overtime payments, notice and severance pay requests",
                             "Restraints of trade, non-solicit, and confidentiality obligations",
                             "Pregnancy & maternity",
                             "Wrongful termination and reinstatement claims"
                           ])
    }

    {
      prepareText("Get in touch with us today to speak with a member of our employment practice.", true)
    }
  </div>
);

export const debtRecovery = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Debt recovery and Enforcement Law")
    }

    {
      prepareText("We provide debt recovery services to Turkish and British companies as well as natural persons to collect their receivables. Especially sale of goods and services contracts between Turkey and the UK may result in commercial disputes and unpaid debts. Our Turkish debt recovery lawyers are dedicated to helping our clients recover debts owed by another party. The stages of our debt recovery process include liaising and negotiating with debtors, drafting effective letters of demand, commencing, and acting on behalf of you in the enforcement or court proceedings.")
    }

    {
      prepareText("Get in touch with us today if you have a receivable from a Turkish or British company or individual.", true)
    }
  </div>
);

export const expertOpinions = (
  <div className={"service__content-text-wrapper"}>
    {
      prepareHeader("Expert Opinions on Turkish Law")
    }

    {
      prepareText("We provide independent expert legal opinions on Turkish law to be used in litigation or alternative dispute resolution entities including Courts or arbitral tribunals seated at foreign jurisdictions. We have extensive experience in providing expert legal opinions for the determination and analysis of all aspects of Turkish law. In legal matters, expert witnesses are required to provide impartial, specialist knowledge on matters imperative to the outcome of the case. We assist many solicitor firms in the UK with expert opinions in Turkish law.")
    }

    {
      prepareText("Get in touch with us today if you need an expert opinion on Turkish law.", true)
    }
  </div>
);