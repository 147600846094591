import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import parse from "html-react-parser";

import {setLoading} from "../../store/actions/creators/appActionCreator";

import BlogService from "../../services/BlogService";
import {message} from "../ui-kit/MessageGate";
import i18nRepo from "../../i18n-repo/i18nRepo";
import Button from "../ui-kit/Button";
import {API_URL} from "../../config/server.config";
import {Magazines} from "../../assets";
import CommonHelper from "../../libs/CommonHelper";

const PAGE_SIZE = 10;

class Insights extends Component
{
  constructor (props)
  {
    super(props);

    this.service = new BlogService();

    this.state = {
      posts: [],
      currentPageIndex: 0,
      hasMore: true
    };
  }

  componentDidMount ()
  {
    this.getPosts();
  }

  getPosts = async () =>
  {
    try
    {
      this.props.setLoading(true);

      const {documents, count} = (await this.service.search({pageSize: PAGE_SIZE, pageIndex: this.state.currentPageIndex}, this.props.language)).result;

      this.setState((previousState, props) => ({
        posts: [...previousState.posts, ...documents],
        hasMore: ((previousState.currentPageIndex * PAGE_SIZE) + PAGE_SIZE) < count,
        currentPageIndex: previousState.currentPageIndex + 1
      }));
    }
    catch (error)
    {
      message({
                type: "error",
                content: i18nRepo.getError("there_is_a_problem_with_getting_posts")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  };

  onClickLoadMore = () =>
  {
    this.getPosts();
  };

  onClickContent = (_post) =>
  {
    this.props.history.push({
                              pathname: "/show-post",
                              state: {
                                _id: _post
                              }
                            });
  };

  render ()
  {
    return (
      <div className={"initial-page insights"}>
        <div className={"insights__content-container"}>
          {
            this.state.posts.map((post, index) => (
              <div
                onClick={() => this.onClickContent(post._id)}
                key={index}
                className={"insights__content"}
              >
                <div className={"insights__image-wrapper"}>
                  <img
                    className={"insights__image"}
                    src={CommonHelper.isExist(post.picture)
                         ? API_URL + "/static/uploads/" + post.picture
                         : Magazines}
                  />
                </div>

                <div className={"insights__content-text-wrapper"}>
                  <div className={"insights__content-subject"}>
                    {
                      post.subject
                    }
                  </div>

                  <div className={"insights__content-content"}>
                    {
                      parse(post.content)
                    }
                  </div>

                  <div className={"insights__content-content-blur"} />
                </div>
              </div>
            ))
          }
        </div>

        {
          this.state.hasMore
          &&
          (
            <div className={"insights__button-wrapper"}>
              <Button
                text={i18nRepo.getSentence("load_more")}
                onClick={this.onClickLoadMore}
              />
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  windowPreferences: state.appReducer.windowPreferences,
  loading: state.appReducer.loading,
  language: state.appReducer.language
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isMobile) => dispatch(setLoading(isMobile))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insights));