import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import _ from "lodash";

import {setLanguage} from "../../store/actions/creators/appActionCreator";

import i18nRepo from "../../i18n-repo/i18nRepo";

import OuterClickHandler from "./OuterClickHandler";

import {SglLogo} from "../../assets/index";

import "../../style/classes.scss";
import CommonHelper from "../../libs/CommonHelper";

class Header extends Component
{
  constructor (props)
  {
    super(props);

    this.menuOptions = [
      {
        key: "about-us",
        url: "/about-us",
        name: i18nRepo.getWord("about_us")
      },
      {
        key: "services",
        url: "services",
        name: i18nRepo.getWord("services"),
        hideOn: {
          tr: true
        }
      },
      /*{
        key: "insights",
        url: "/insights",
        name: i18nRepo.getWord("insights")
      },*/
      {
        key: "contact",
        url: "/contact",
        name: i18nRepo.getWord("contact")
      }
    ];

    this.state = {
      isMobileMenuVisible: false
    };
  }

  onClickLogo = () =>
  {
    this.props.history.push("/");
  };

  onClickOption = (url) =>
  {
    this.props.history.push(url);
    if (this.state.isMobileMenuVisible)
    {
      this.setState((previousState, props) => ({
        isMobileMenuVisible: false
      }));
    }
  };

  onClickMenu = () =>
  {
    this.setState((previousState, props) => ({
      isMobileMenuVisible: !previousState.isMobileMenuVisible
    }));
  };

  closeMobileMenu = () =>
  {
    this.setState((previousState, props) => ({
      isMobileMenuVisible: false
    }));
  };

  onClickLanguage = (language) =>
  {
    this.props.setLanguage(language);
  };

  render ()
  {
    const {windowPreferences} = this.props;
    const languages = i18nRepo.languages;

    const generateLanguageMenu = () =>
    {
      return (
        <div className={"header__menu__language-selection"}>
          {
            Object.values(languages).map((language, index) => (
              <div
                lang={language}
                key={index}
                className={"header__menu__language " + (_.isEqual(this.props.language, language)
                                                        ? "header__menu__language__selected"
                                                        : "")}
                onClick={() => this.onClickLanguage(language)}
              >
                {
                  i18nRepo.languageDisplays[language]
                }
              </div>
            ))
          }
        </div>
      );
    };

    const generateMenu = () =>
    {
      return this.menuOptions.map((option, index) =>
                                  {
                                    if (CommonHelper.isExist(option.hideOn) && option.hideOn[this.props.language])
                                    {
                                      return;
                                    }

                                    return (
                                      <div
                                        className={"header__menu__option"}
                                        key={index}
                                        onClick={() => this.onClickOption(option.url)}
                                      >
                                        {
                                          option.name
                                        }
                                      </div>
                                    );
                                  });
    };

    return (
      <OuterClickHandler onClickOut={this.closeMobileMenu}>
        <div className={"header-container"}>
          {
            (windowPreferences.isMobile && this.state.isMobileMenuVisible)
            &&
            (
              <div className={"header__mobile-menu-wrapper"}>
                <div className={"header__menu"}>
                  {
                    generateLanguageMenu()
                  }

                  {
                    generateMenu()
                  }
                </div>
              </div>
            )
          }

          <div className={"header"}>
            <div className={"header__logo-container"}>
              <SglLogo
                className={"header__logo"}
                onClick={this.onClickLogo}
              />
            </div>

            {
              windowPreferences.isMobile
              ?
              (
                <div
                  className={"header__mobile-menu-switch"}
                  onClick={this.onClickMenu}
                >
                  {
                    i18nRepo.getWord("menu")
                  }
                </div>
              )
              :
              (
                <div className={"header__menu"}>
                  {
                    generateLanguageMenu()
                  }

                  {
                    generateMenu()
                  }
                </div>
              )
            }
          </div>
        </div>
      </OuterClickHandler>
    );
  }
}

const mapStateToProps = (state) => ({
  windowPreferences: state.appReducer.windowPreferences,
  language: state.appReducer.language
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(setLanguage(language))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
