import React, {Component} from "react";
import {connect} from "react-redux";
import {withGoogleMap, GoogleMap, Marker} from "react-google-maps";

import {setLoading} from "../../store/actions/creators/appActionCreator";

import {ADDRESS, EMAIL_PHONE} from "../../config/contact.config";

import i18nRepo from "../../i18n-repo/i18nRepo";

import ContactService from "../../services/ContactService";

import Form from "../ui-kit/Form";
import Input from "../ui-kit/Input";
import Button from "../ui-kit/Button";
import TextArea from "../ui-kit/TextArea";
import {message} from "../ui-kit/MessageGate";

import {Phone} from "../../assets";

class Contact extends Component
{
  constructor (props)
  {
    super(props);

    this.contactService = new ContactService();

    this.formRef = React.createRef();
  }

  onSubmitForm = async (values) =>
  {
    try
    {
      this.props.setLoading(true);
      throw new Error();
      await this.contactService.contact(values, this.props.language);
      this.formRef.current.reset();

      message({
                type: "success",
                content: i18nRepo.getSentence("your_message_is_successfully_send")
              });
    }
    catch (error)
    {
      message({
                type: "error",
                content: i18nRepo.getError("your_contact_request_could_not_be_registered")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  };

  onClickSubmit = () =>
  {
    this.formRef.current.submit();
  };

  render ()
  {
    const AddressMap = withGoogleMap((props) => (
      <GoogleMap
        defaultCenter={ADDRESS.position}
        defaultZoom={16}
      >
        <Marker
          key="1"
          position={ADDRESS.position}
        />
      </GoogleMap>
    ));

    return (
      <div className={"initial-page contact"}>
        <div className={"contact__image-wrapper"}>
          <img
            src={Phone}
            className={"contact__background-image"}
          />
        </div>

        <div className={"contact__content-wrapper"}>
          <div className={"contact__header-wrapper"}>
            <div className={"contact__header"}>
              {
                i18nRepo.getSentence("we_are_here_to_help")
              }
            </div>
          </div>

          <div className={"contact__dummy-header-wrapper"}>

          </div>

          <div className={"contact__content"}>
            {/*
            <div className={"contact__form-container"}>
              <div className={"contact__form-wrapper"}>
                <Form
                  formItems={[
                    {
                      component: Input,
                      props: {
                        placeholder: "john@doe.com"
                      },
                      fieldName: "email",
                      label: i18nRepo.getWord("email"),
                      config: {
                        required: true,
                        message: i18nRepo.getError("email_required")
                      }
                    },
                    {
                      component: Input,
                      props: {
                        placeholder: "John"
                      },
                      fieldName: "firstName",
                      label: i18nRepo.getWord("first_name"),
                      config: {
                        required: true,
                        message: i18nRepo.getError("first_name_required")
                      }
                    },
                    {
                      component: Input,
                      props: {
                        placeholder: "Doe"
                      },
                      fieldName: "lastName",
                      label: i18nRepo.getWord("last_name"),
                      config: {
                        required: true,
                        message: i18nRepo.getError("last_name_required")
                      }
                    },
                    {
                      component: Input,
                      props: {
                        placeholder: i18nRepo.getWord("phone_placeholder")
                      },
                      fieldName: "phone",
                      label: i18nRepo.getWord("phone"),
                      config: {
                        required: true,
                        message: i18nRepo.getError("phone_required")
                      }
                    },
                    {
                      component: Input,
                      props: {
                        placeholder: i18nRepo.getWord("subject")
                      },
                      fieldName: "subject",
                      label: i18nRepo.getWord("subject"),
                      config: {
                        required: true,
                        message: i18nRepo.getError("subject_required")
                      }
                    },
                    {
                      component: TextArea,
                      props: {
                        placeholder: i18nRepo.getWord("content")
                      },
                      fieldName: "content",
                      label: i18nRepo.getWord("content"),
                      config: {
                        required: true,
                        message: i18nRepo.getError("content_required")
                      }
                    }
                  ]}
                  ref={this.formRef}
                  onSubmit={this.onSubmitForm}
                />
              </div>

              <div className={"contact__form-button-wrapper"}>
                <Button
                  onClick={this.onClickSubmit}
                  text={i18nRepo.getWord("submit")}
                />
              </div>
            </div>
            */}
            <div className={"contact__address-wrapper"}>
              <AddressMap
                containerElement={<div className={"contact__address-map"} />}
                mapElement={<div style={{height: `100%`}} />}
              >
              </AddressMap>

              <div className={"contact__address-phone-wrapper"}>
                <div className={"contact__address"}>
                  <div className={"contact__address-header"}>
                    {
                      i18nRepo.getWord("address")
                    }
                  </div>

                  <div className={"contact__address-detail"}>
                    {
                      ADDRESS.detail1
                    }
                  </div>

                  <div className={"contact__address-detail"}>
                    {
                      ADDRESS.city
                    }
                  </div>
                </div>

                <div className={"contact__phone"}>
                  <div className={"contact__address-header"}>
                    {
                      i18nRepo.getWord("emailPhone")
                    }
                  </div>

                  <div className={"contact__address-detail"}>
                    {
                      EMAIL_PHONE.email
                    }
                  </div>

                  <div className={"contact__address-detail"}>
                    {
                      EMAIL_PHONE.phone
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.appReducer.language
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
