import {Section, SectionHeader, Text, Header, SubHeader, UnorderedList} from "../generator/Generator";

export default (
  <div className={"privacy-policy"}>
    <Section>
      <Header text={"GİZLİLİK POLİTİKASI"} />

      <Text
        text={"SGL International Legal Consulting ('SGL'), tüm müvekkillerinin, bağlantılarının ve websitesi ziyaretçilerinin mahremiyetine saygı göstermeyi taahhüt eder. Bize sağladığınız veya hakkınızda elde ettiğimiz veya tuttuğumuz kişisel verileri nasıl kullandığımızı ve koruduğumuzu anlamak ve sahip olduğumuz kişisel verilerle ilgili haklarınızın neler olduğunu anlamak için lütfen aşağıdaki gizlilik politikasını okuyun. Veri sorumlusu, herhangi bir kişisel verinin hangi amaçla ve hangi şekilde işlendiğini veya işleneceğini tek başına veya müştereken belirleyen bir kişi veya kuruluştur. Bu politika, veri sorumlusu olarak SGL tarafından hazırlanmıştır."} />
    </Section>

    <Section>
      <SectionHeader text={"SGL HANGİ KİŞİSEL VERİLERİ İŞLEMEKTEDİR?"} />

      <SubHeader text={"Genel web sitemizden"} />
      <Text
        text={"Web sitesini ziyaret ettiğinizde, örneğin haber bültenimize kaydolduğunuzda veya sizinle iletişime geçmemizi talep ettiğinizde bazı kişisel verilerinizi bizimle paylaşmanız gerekebilir. Adınız, unvanınız, posta adresiniz, telefon numaranız ve / veya e-posta adresiniz dahil olmak üzere kendiniz hakkında bazı bilgiler vermeniz istenebilir."} />

      <SubHeader text={"Otomatik olarak"} />
      <Text
        text={"Çerezleri ve diğer teknolojileri kullanarak web sitelerimizi kullanımınız hakkında otomatik olarak bilgi toplayabiliriz. Bu amaçla, IP adresinizi (internetteki bir bilgisayarı tanımlayan bir dizi sayı), diğer şeylerin yanı sıra, internet trafiği verilerini ve tarayıcınızın türü ve bilgisayarınızla ilgili verileri toplamak için kullanabiliriz. Çerezleri almak istemiyorsanız, web sitemizin veya hizmetlerimizin ziyaretçilere sunulması için gerekli olmadıkları sürece tarayıcı ayarlarınızı kullanarak bunları reddedebilirsiniz."} />

      <SubHeader text={"Yasal işler için"} />
      <Text
        text={"Yasal çalışma için bir maliyet tahmini talep ederseniz, ücretsiz ilk tavsiye verilirse veya firmamızın müvekkili olursanız, doğum tarihiniz, destek talep ettiğiniz konu ve koşullarınız ve ayrıca sizin, personelinizin ve konunun diğer tarafları ile ilgili bilgiler gibi kimliğinizi doğrulamak için bazı kişisel verilerinize ihtiyacımız olabilecektir. İhtiyaç duyduğumuz bilgiler size tarafımızca açıklanacak ve/veya konunuzun niteliğine anlaşmamızda belirtilecektir. Ayrıca, tarafımıza verdiğiniz talimatınızın bir parçası olarak ilgili olduğunu düşündüğünüz ek bilgileri de bize sağlayabilirsiniz."} />

      <SubHeader text={"Üçüncü şahıslardan"} />
      <Text
        text={"Ayrıca emlakçılar, muhasebeciler, bankalar, eksperler, tıp uzmanları, mahkemeler, düzenleyici kurumlar ve konunuzla ilgili diğer danışmanlar ve uzmanlar gibi üçüncü şahıslardan siz (veya personeliniz) hakkında bilgi alabiliriz. Müvekkillerimizden biriyle bir işlem veya anlaşmazlığın tarafıysanız veya onlarla bir Müvekkilin kiracısı veya çalışanı gibi bir bağlantınız varsa, Müvekkillerimiz de bize sizin hakkınızda bilgi sağlayabilir. Alternatif olarak, doğrudan talebimiz üzerine veya başka bir şekilde hakkınızdaki bilgileri bize sağlayabilirsiniz."} />
    </Section>

    <Section>
      <SectionHeader text={"SGL BU BİLGİLERİ NASIL KULLANIR?"} />

      <Text
        text={"Kişisel verilerinizi, sağlandığı veya tarafımızdan toplandığı belirli amaçlar için kullanacağız, örneğin:"} />
      <UnorderedList
        listItems={[
          "Sunduğumuz hizmetlerle ilgili talep edebileceğiniz bilgileri sağlamak için, örneğin belirli yasal tavsiyelere yardımcı olup olamayacağımızı tespit edebilmek",
          "Sizi uzman avukatlarımızla tanıştırmak için sizinle iletişime geçmek",
          "Müvekkillerimize hem İngiltere, hem Türkiye ve / veya diğer ülkelerdeki diğer uzman danışmanlara yönlendirmek de dahil olmak üzere hukuki hizmetler sunmak",
          "Yasal ve düzenleyici yükümlülüklerimize uymak",
          "Kimliğinizi doğrulamak ve kara para aklamanın önlenmesi amacıyla ilgili arka plan koşullarını kontrol etmek",
          "Geri bildiriminiz, sorunuz veya şikayetinizle ilgilenmek için",
          "Hizmetlerimiz hakkındaki görüşleriniz için sizinle iletişime geçmek için"
        ]}
      />

      <Text
        text={"Kişisel verilerinizi genel olarak işimizi yönetmek, desteklemek, iyileştirmek ve geliştirmek ve yasal haklarımızı uygulamak için de kullanırız."} />
      <Text
        text={"Sizin hakkınızda topladığımız bilgileri pazarlama ve ağırlama amaçlarıyla da kullanabiliriz, örneğin:"} />
      <UnorderedList
        listItems={[
          "Bizimle ve sunduğumuz diğer hizmetler hakkında ilginizi çekebilecek iletişimleri sağlamak, örneğin size haber bültenleri göndermek",
          "Size ilgili hukuk ve uygulama alanlarında güncellemeler sağlamak için",
          "Sizi konferanslara veya bir misafirperverlik etkinliğine davet etmek"
        ]}
      />

      <Text
        text={"Çerezler ve benzer teknolojiler aracılığıyla toplanan bilgiler, web sitelerimize yapılan ziyaretlerle ilgili bilgileri ölçmek ve analiz etmek, web sitelerini ziyaretçiler için daha iyi hale getirmek ve teknik performansı iyileştirmek için kullanılır. Söz konusu verileri sizi kişisel olarak tanımlamak veya hakkınızda herhangi bir karar vermek için kullanmayacağız."} />
    </Section>

    <Section>
      <SectionHeader text={"İŞLEME AMAÇLARI"} />

      <Text
        text={"Kişisel verilerinizi işlemek için yasal bir dayanağımız olması gerekmektedir. Bu sizin verilerinize nasıl ve neden sahip olduğumuza ilişkin koşullara göre değişecek olmakla beraber tipik örnekler aşağıdaki gibidir:"} />
      <UnorderedList
        listItems={[
          "Potansiyel ve mevcut müvekkiller ile ilişki kurmaya ve onlara hizmet vermeye çalışan bir hukuk firması olarak sunulacak çözümleri verimli hale getirebilmek adına gerekli bazı kişisel verilerin elde edilme zorunluluğu çerçevesinde",
          "Belirli pazarlama faaliyetleriyle ilgili olarak bilgilerinizi işlememiz için bize izin verdiğiniz durumlarda",
          "Taraf olduğunuz bir sözleşmeyle ilgili olarak veya bir sözleşmeye taraf olmadan önce sizin adınıza hukuki hizmetleri yürütmemiz için bize talimat vermek istediğiniz için",
          "Tabi olduğumuz yasal bir yükümlülüğe uymak için gerekli olması halinde",
          "Hayati çıkarlarınızı korumak için, örneğin, tesislerimizde hastalanacak veya yaralanacak kadar talihsizseniz"
        ]}
      />

      <Text
        text={"Herhangi bir özel veri kategorisini, yani ırksal veya etnik köken, siyasi görüşler, dini veya felsefi inançları veya sendika üyeliğini, genetik verileri, bireyleri, sağlık verilerini veya sizinle ilgili verileri benzersiz bir şekilde tanımlamak amacıyla biyometrik verilerin işlenmesi gibi bilgileri işlersek cinsel yaşam veya cinsel yönelim, işleme için başka bir yasal dayanağa sahip olmalıyız. Bu şunları içerebilir:"} />
      <UnorderedList
        listItems={[
          "Bize bunun için açık rızanızı verdiyseniz",
          "Hayati çıkarlarınızı veya bir başkasının hayati çıkarlarını korumak için işlemenin gerekli olduğu durumlarda",
          "Bilgiyi herkese açık hale getirdiğiniz durumda",
          "Yasal iddiaların oluşturulması, uygulanması veya savunulması için gerekli olan işlemler için, hukuki işlemlerinizin gerektirdiği ölçüde",
          "Önemli kamu yararı nedeniyle gerekli olan işlem örneğin dolandırıcılık ya da diğer yasadışı veya dürüst olmayan faaliyetlerin önlenmesi veya tespiti ile ilgili olarak faaliyetlerde bulunulmasının gerektiği durumda"
        ]}
      />

      <Text
        text={"Cezai mahkumiyetleriniz veya suçlarınızla ilgili herhangi bir bilgiyi işlersek, genellikle aşağıdaki yasal dayanaklardan birine dayanmaktayızdır:"} />
      <UnorderedList
        listItems={[
          "Yasa dışı eylemleri önlemek veya tespit etmek",
          "Yasa dışı eylemler ile ilgili yasal gerekliliklerimize uymak",
          "Terörün finansmanı veya kara para aklama şüphelerini kontrol etmek",
          "Yasal tavsiye almamız veya yasal hakları tesis etmemiz, kullanmamız veya savunmamız gerektiğinde"
        ]}
      />
    </Section>

    <Section>
      <SectionHeader text={"KİŞİSEL VERİLERİNİZİN AKTARILMASI"} />

      <Text
        text={"We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy; please see section ‘Protection of your information’ below\n" +
              "SGL'ye sağladığınız kişisel verilerden bazıları, bizim için ve bizim adımıza verileri işleyen üçüncü taraf kuruluşlara aktarılabilir, depolanabilir ve işlenebilir. Bu üçüncü şahıslar, Birleşik Krallık'ta, Türkiye'de veya EEA dışında başka bir yerde yerleşik olabilir (veya bilgileri depolayabilir veya işleyebilir). Birçok hukuk firmasında olduğu gibi, bu üçüncü taraflar, üçüncü taraf bilgi teknolojisi platformlarını (bulut tabanlı platformlar dahil), idari ve destek hizmetleri tedarikçilerini ve diğer uzman ürünlerin tedarikçilerini içerebilir."} />
      <Text
        text={"Kişisel verilerinizi, birlikte çalıştığımız veya sizi ek veya ayrı tavsiye için yönlendirdiğimiz diğer kuruluşlara veya profesyonel danışmanlara da aktarabiliriz. Kişisel verilerinizi, pazarlama ve iletişimlerimizde bize yardımcı olan hizmet sağlayıcılarla paylaşabiliriz."} />
      <Text
        text={"Ayrıca verileri belirli yasalar uyarınca veya Mahkeme veya diğer yetkili düzenleyici kurumların emriyle ifşa etmek zorunda kalabiliriz veya geçerli veri koruma yasaları kapsamında açıklamamıza izin verilebilir. Verilerinizin güvenli bir şekilde ve bu gizlilik politikasına uygun olarak işlenmesini sağlamak için makul olarak gerekli tüm adımları atacağız; lütfen aşağıdaki 'Bilgilerinizin korunması' bölümüne bakın."} />
    </Section>

    <Section>
      <SectionHeader text={"KİŞİSEL VERİLERİNİZİN KORUNMASI"} />

      <Text
        text={"Web sitemizde, elimizde bulunan kişisel bilgilerin kaybolması, kötüye kullanılması veya yetkisiz işlenmesi veya ifşa edilmesi risklerine karşı koruma sağlamak ve bunları en aza indirmek için idari, teknik ve fiziksel önlemler alıyoruz."} />
      <Text
        text={"Bilgileri üçüncü şahıslara bizim adımıza işleyebilmeleri için aktardığımızda, sağlayıcıların onlara veri aktarımı ve güvenliğini sağlamaya yönelik ilgili yasal veya düzenleyici gereksinimleri karşılamasını veya aşmasını sağlıyoruz."} />
      <Text
        text={"Ayrıca, bilgilerin Birleşik Krallık’a, Türkiye’ye veya EEA dışındaki bir ülkeye veya uluslararası kuruluşa aktarılması durumunda, bu tür aktarımları yöneten ilgili yasal kurallara uyacağımızı da garanti edeceğiz."} />
      <Text
        text={"Kişisel bilgilerinizi, toplanma amacı için gerekenden daha uzun süre saklamıyoruz. Müvekkillerimize yönelik bilgiler, müvekkillerimizden kiracıları, çalışanları, üçüncü şahıslar veya benzer kişiler hakkında aldığımız kişisel verileri nasıl güvenli bir şekilde sakladığımıza ilişkin ayrıntılar dahil olmak üzere sözleşme mektubumuzda ve iş şartlarımızda sağlanmaktadır."} />
    </Section>

    <Section>
      <SectionHeader text={"HAKLARINIZ"} />

      <Text
        text={"Kişisel bilgilerinizle ilgili belirli haklara sahipsiniz, ancak bu haklar her durumda veya hakkınızda tuttuğumuz tüm bilgiler için geçerli olmayacaktır. Örneğin, yasal haklarımızı oluşturmak, kullanmak veya savunmak için bilgileri tutmaya ve işlemeye devam etmemiz gerekebilir. Bu konuda bilgi almak için lütfen bizimle iletişime geçiniz."} />
      <Text text={"Aşağıdakileri her daim bizden talep etme hakkına sahipsiniz:"} />
      <UnorderedList
        listItems={[
          "Kişisel verilerinin işlenip işlenmediğini öğrenme,",
          "Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,",
          "Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,",
          "Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,",
          "Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,",
          "Kişisel verilerin silinmesini veya yok edilmesini isteme,",
          "Kişisel verilerin düzeltilmesi, silinmesi veya yok edilmesine ilişkin işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,",
          "İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,",
          "Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme"
        ]}
      />

      <Text
        text={"İsteğinize geçerli yasal süre içinde yanıt vereceğiz. Kimliğinizden emin değilsek, kim olduğunuzu doğrulamamız için daha fazla bilgi vermenizi isteyebiliriz."} />
    </Section>

    <Section>
      <SectionHeader text={"PAZARLAMA"} />

      <Text
        text={"Firmanın bir Müvekkili olursanız, kişisel bilgilerinizi, sizi misafirperverlik etkinliklerine veya diğer etkinliklere davet etmek ve / veya sizin veya işletmeniz için ilginizi çekebileceğini düşündüğümüz bilgileri size göndermek için kullanabiliriz. Bu, bilgilerinizi bu şekilde kullanmak bir hukuki danışmanlık firması olarak meşru menfaatlerimiz dahilindedir."} />
      <Text
        text={"Profesyonel bağlantılarımızla ilgili kişisel bilgileri, onları ağırlama etkinliklerine veya diğer seçilmiş etkinliklere davet etmek ve / veya kendilerinin veya işlerinin ilgisini çekebileceğini düşündüğümüz bilgileri göndermek için de kullanabiliriz. Bu aynı zamanda meşru menfaatlerimiz dahilindedir."} />
      <Text
        text={"Bilgilerinizi bize sunduğunuzda tarafımızca direkt pazarlama materyalleri ve bilgileri alıp almamakla ilgili yanıtınız sorulacaktır. Bilgilerinizi gönderdikten veya verdikten sonra herhangi bir doğrudan pazarlama materyali veya iletişim almak istemiyorsanız, lütfen bize bir e-posta (herhangi bir e-posta mesajının başlığında ABONELİKTEN KALDIR yazarak) için sizinle iletişime geçilmesini istemediğinizi belirten bir e-posta gönderin."} />
    </Section>

    <Section>
      <SectionHeader text={"ÇEREZLER"} />

      <Text
        text={"Tarayıcılar veya izleme tanımlama bilgileri olarak da bilinen tanımlama bilgileri, bir web sitesini ziyaret ettiğinizde bilgisayarınıza eklenen küçük metin dosyalarıdır. Web sitelerinin belirli işlevleri yerine getirmelerine yardımcı olurlar, örneğin bir web sitesinin kısıtlı bir bölümünde oturum açarsanız, alışveriş sepetleri için ve izleme amacıyla kim olduğunuzu bilmek için."} />
      <Text
        text={"Gerekli Çerezler: SGL'de, web sitemizi işletmemizi sağlamak için gerekli çerezleri kullanırız. Bu çerezler, web sitemize geldikten kısa bir süre sonra ve sayfalarında gezinirken cihazınıza yerleştirilecektir."} />
      <Text
        text={"İsteğe Bağlı Çerezler: Cihazınıza belirli çerezleri veya diğer benzer teknolojileri yerleştirmek için izninizi isteyeceğiz. Bu sitedeki herhangi bir sayfanın altındaki gizlilik panosunu kullanarak ve kapat okunu tıklayarak, çerez kullanımına yönelik her türlü onayınızı geri çekebilir veya diğer çerez tercihlerini yönetebilirsiniz. Güncellenen ayarların etkili olması için sayfayı yenilemek gerekebilir."} />
      <Text
        text={"Web sitemizdeki Çerez Beyanı'ndaki onayınızı istediğiniz zaman değiştirebilir veya geri çekebilirsiniz."} />
    </Section>

    <Section>
      <SectionHeader text={"BİZİMLE NASIL İLETİŞİME GEÇEBİLİRSİNİZ"} />

      <Text
        text={"Bu politikayı, kişisel verilerinizi nasıl kullandığımızı, haklarınızı kullanmak veya geri bildirimde bulunmak veya bilgilerinizin kullanımıyla ilgili şikâyette bulunmak için iletişime geçmek isterseniz, lütfen selin@sgllegal.co.uk adresinden bizimle iletişime geçin."} />
    </Section>
  </div>
);