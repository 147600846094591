import React, {Component} from "react";
import {connect} from "react-redux";

import {setScrollDisabled} from "../../store/actions/creators/appActionCreator";

import OuterClickHandler from "../elements/OuterClickHandler";

class Modal extends Component
{
  shouldComponentUpdate (nextProps, nextState, nextContext)
  {
    if (!nextProps.removeScrollDisable)
    {
      if (!this.props.visible && nextProps.visible)
      {
        this.props.setScrollDisabled(true);
      }

      if (this.props.visible && !nextProps.visible)
      {
        this.props.setScrollDisabled(false);
      }
    }

    return true;
  }

  onClickClose = () =>
  {
    this.props.onClose();
  };

  render ()
  {
    return this.props.visible
           &&
           (
             <div className={"modal"}>
               <div className={"modal__background"} />

               <OuterClickHandler onClickOut={this.onClickClose}>
                 <div className={"modal__content"}>
                   <div className={"modal__header"}>
                     <i
                       className="fas fa-times modal__close"
                       onClick={this.onClickClose}
                     />
                   </div>

                   <div className={"modal__children"}>
                     {
                       this.props.children
                     }
                   </div>

                 </div>
               </OuterClickHandler>
             </div>
           );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setScrollDisabled: (scrollDisabled) => dispatch(setScrollDisabled(scrollDisabled))
});

export default connect(null, mapDispatchToProps)(Modal);