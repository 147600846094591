import React, {Component} from "react";
import CommonHelper from "../../libs/CommonHelper";

class TextArea extends Component
{
  constructor (props)
  {
    super(props);

    this.state = {
      value: CommonHelper.isExist(props.value)
             ? props.value
             : ""
    };
  }

  getValue = () =>
  {
    return CommonHelper.isInitialized(this.state.value)
           ? this.state.value
           : null;
  };

  setValue = (value) =>
  {
    this.setState((previousState, props) => ({
      value: CommonHelper.isExist(value)
             ? value
             : ""
    }));
  };

  onChange = (event) =>
  {
    const value = event.target.value;

    if (CommonHelper.isExist(this.props.onChange))
    {
      this.props.onChange(value);
    }

    this.setValue(value);
  };

  render ()
  {
    return (
      <textarea
        {...this.props}
        className={"textarea " + (CommonHelper.isExist(this.props.className)
                                  ? this.props.className
                                  : "")}
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}

export default TextArea;