import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {connect} from "react-redux";

import RouteHelper from "../libs/RouteHelper";
import i18nRepo from "../i18n-repo/i18nRepo";

import Home from "../components/containers/Home";
import AboutUs from "../components/containers/about-us/AboutUs";
import Services from "../components/containers/services/Services";
import Insights from "../components/containers/Insights";
import Contact from "../components/containers/Contact";
import ShowPost from "../components/containers/ShowPost";

class Router extends Component
{
  constructor (props)
  {
    super(props);

    i18nRepo.setLanguage(props.language);

    this.routeMap = {
      routes: [
        RouteHelper.generateRoute("/", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, false, Home),

        RouteHelper.generateRoute("/about-us", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, AboutUs, Home),
        RouteHelper.generateRoute("/services", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, Services, Home),
        /*RouteHelper.generateRoute("/insights", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, Insights, Home),*/
        RouteHelper.generateRoute("/show-post", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, ShowPost, Home),
        RouteHelper.generateRoute("/contact", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, Contact, Home)
      ],
      redirectRoute: "/"
    };
  };

  shouldComponentUpdate (nextProps, nextState, nextContent)
  {
    if (!_.isEqual(this.props.location.pathname, nextProps.location.pathname))
    {
      window.scrollTo(0, 0);
    }

    return true;
  }

  render ()
  {
    return RouteHelper.renderRoute(this.routeMap);
  }
}

const mapStateToProps = (state) => ({
  language: state.appReducer.language
});

export default withRouter(connect(mapStateToProps)(Router));
