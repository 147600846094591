import React, {Component} from "react";
import {connect} from "react-redux";

import {setScrollDisabled} from "../../store/actions/creators/appActionCreator";

import Spinner from "./Spinner";

import "../../style/classes.scss";

class Loader extends Component
{
  shouldComponentUpdate (nextProps, nextState, nextContext)
  {
    if (!this.props.loading && nextProps.loading)
    {
      this.props.setScrollDisabled(true);
    }

    if (this.props.loading && !nextProps.loading)
    {
      this.props.setScrollDisabled(false);
    }

    return true;
  }

  render ()
  {
    return (
      <div className={"loader-wrapper"}>
        {
          this.props.loading
          &&
          (
            <div className={"loader"}>
              <div className={"loader-spinner"}>
                <Spinner />
              </div>
            </div>
          )
        }

        {
          this.props.children
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setScrollDisabled: (scrollDisabled) => dispatch(setScrollDisabled(scrollDisabled))
});

export default connect(null, mapDispatchToProps)(Loader);