export const sentenceRepoEN = {
  cookie_text: "In order to make our site work we use necessary cookies. We would also like to set certain\n" +
               "optional analytics and marketing cookies on your device to help us improve our site. You can\n" +
               "agree to these optional cookies by pressing the accept button. For detailed\n" +
               "information about how we use cookies on our site, see our ½&½#æPrivacy Policyæ½&½.",

  eliminate_the_legal_barriers_between_turkey_and_england: "Eliminate the legal barriers between Turkey and United Kingdom.",
  explore: "Explore!",

  follow_us: "Follow us!",

  get_in_touch: "Get in touch!",

  learn_more: "Learn more",
  load_more: "Load more",
  local_turkish_legal_expertise: "Local Turkish legal expertise, international presence...",

  service__sentence: "Our practice areas are structured to provide solutions to various types of problems.",

  tell_us_what_you_think: "Tell us what you think.",
  thank_you_for_your_opinions: "Thanks for your opinions!",
  the_interface_between_english_and_turkish_legal_systems: "The interface between English and Turkish legal systems.",
  the_legal_gateway_from_england_to_turkey: "The legal gateway from and to Turkey.",

  we_are_here_to_help: "We are here to help!",
  we_specialize_in_advising_clients: "Being dual-qualified solicitors in both England & Wales and Turkey, we specialize in advising clients on cross-border legal issues between two countries.",
  why_choose_us: "Why choose us?",

  your_message_is_successfully_send: "Your message is successfully sent."
};
