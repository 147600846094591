import Service from "./Service";

import {API_URL, axiosInstance} from "../config/server.config";

const route = "/posts";

class BlogService extends Service
{
  constructor ()
  {
    super(route, {
      defaultRoutes: [
        Service.ROUTE_MAP.GET_BY_ID
      ],
      authenticated: false
    });
  }

  search = async (body, language) =>
  {
    try
    {
      const config = this._generateConfig(language);

      this.deleteFields(body);

      const result = await axiosInstance.post(API_URL + this._prepareRoute() + "/search" , body, config);
      return this._extractDataFromSuccess(result);
    }
    catch (error)
    {
      throw this._extractDataFromError(error);
    }
  }
}

export default BlogService;