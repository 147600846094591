import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";

import i18nRepo from "../../i18n-repo/i18nRepo";

import PRIVACY_POLICY from "./privacy-policy";
import DISCLAIMER from "./disclaimer";

import Modal from "../ui-kit/Modal";

import {SglLogo} from "../../assets/index";

class Footer extends Component
{
  constructor (props)
  {
    super(props);

    this.state = {
      privacyPolicyVisible: false,
      disclaimerVisible: false
    };
  }

  onClickContact = () =>
  {
    this.props.history.push("/contact");
  };

  onClickPrivacyPolicy = () =>
  {
    this.setState((previousState, props) => ({
      privacyPolicyVisible: true
    }));
  };

  onClickDisclaimer = () =>
  {
    this.setState((previousState, props) => ({
      disclaimerVisible: true
    }));
  };

  onCloseModal = () =>
  {
    this.setState((previousState, props) => ({
      privacyPolicyVisible: false,
      disclaimerVisible: false
    }));
  };

  render ()
  {

    const generateModalContent = () =>
    {
      switch (true)
      {
        case this.state.privacyPolicyVisible:
          return PRIVACY_POLICY[this.props.language];

        case this.state.disclaimerVisible:
          return DISCLAIMER[this.props.language];

        default:
          return;
      }
    };
    return (
      <div className={"footer"}>
        <Modal
          visible={this.state.disclaimerVisible || this.state.privacyPolicyVisible}
          onClose={this.onCloseModal}
        >
          {
            generateModalContent()
          }
        </Modal>

        <div className={"footer__social-media"}>
          {
            !this.props.windowPreferences.isMobile
            &&
            (
              <div className={"footer__social-media__logo-wrapper"}>
                <SglLogo
                  className={"footer__social-media__logo"}
                />
              </div>
            )
          }

          <div className={"footer__social-media__right-content"}>
            <div className={"footer__social-media__text"}>
              {
                i18nRepo.getSentence("follow_us")
              }
            </div>

            <div className={"footer__social-media__icons"}>
              <a
                target={"_blank"}
                href={"https://www.linkedin.com/company/sgl-international-legal-consulting/"}
                className={"footer__social-media__icon-wrapper"}
              >
                <i className="fab fa-linkedin footer__social-media__icon" />
              </a>

              <a
                target={"_blank"}
                href={"https://www.facebook.com/sgllegal/"}
                className={"footer__social-media__icon-wrapper"}
              >
                <i className="fab fa-facebook footer__social-media__icon" />
              </a>
            </div>
          </div>

          {
            this.props.windowPreferences.isMobile
            &&
            (
              <div className={"footer__social-media__logo-wrapper"}>
                <SglLogo
                  className={"footer__social-media__logo"}
                />
              </div>
            )
          }
        </div>

        <div className={"footer__actions"}>
          <div className={"footer__actions__menu-wrapper"}>
            <div
              onClick={this.onClickPrivacyPolicy}
              className={"footer__actions__menu-item"}
            >
              {
                i18nRepo.getWord("privacy_policy")
              }
            </div>

            <div
              onClick={this.onClickDisclaimer}
              className={"footer__actions__menu-item"}
            >
              {
                i18nRepo.getWord("disclaimer")
              }
            </div>

            <div
              onClick={this.onClickContact}
              className={"footer__actions__menu-item"}
            >
              {
                i18nRepo.getWord("contact")
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  windowPreferences: state.appReducer.windowPreferences,
  language: state.appReducer.language
});

export default withRouter(connect(mapStateToProps)(Footer));
