import React, {Component} from "react";
import {connect} from "react-redux";

import {setScrollDisabled} from "../../store/actions/creators/appActionCreator";

import i18nRepo from "../../i18n-repo/i18nRepo";

import PRIVACY_POLICY from "./privacy-policy";

import Button from "../ui-kit/Button";
import Modal from "../ui-kit/Modal";

class CookieAccepted extends Component
{
  constructor (props)
  {
    super(props);

    this.state = {
      privacyPolicyVisible: false
    };
  }

  componentDidMount ()
  {
    this.props.setScrollDisabled(true);
  }

  componentWillUnmount ()
  {
    this.props.setScrollDisabled(false);
  }

  onClickPrivacyPolicy = () =>
  {
    this.setState((previousState, props) => ({
      privacyPolicyVisible: true
    }));
  };

  onCloseModal = () =>
  {
    this.setState((previousState, props) => ({
      privacyPolicyVisible: false
    }));
  };

  render ()
  {
    return (
      <div className={"cookie-accepted"}>
        <Modal
          removeScrollDisable
          visible={this.state.privacyPolicyVisible}
          onClose={this.onCloseModal}
        >
          {
            PRIVACY_POLICY[this.props.language]
          }
        </Modal>

        <div className={"cookie-accepted__background"} />

        <div className={"cookie-accepted__box"}>
          <div className={"cookie-accepted__text"}>
            {
              i18nRepo.getSentence("cookie_text", this.onClickPrivacyPolicy)
            }
          </div>

          <Button
            text={i18nRepo.getWord("accept")}
            onClick={this.props.onClickAccept}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.appReducer.language
});

const mapDispatchToProps = (dispatch) => ({
  setScrollDisabled: (scrollDisabled) => dispatch(setScrollDisabled(scrollDisabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieAccepted);