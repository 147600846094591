import React, {Component} from "react";

import "../../style/classes.scss";

class Layout extends Component
{
  render ()
  {
    return (
      <div className={"layout"}>
        <div className={"layout__header-container"}>
          {
            this.props.header
          }
        </div>

        <div className={"layout__content-container"}>
          {
            this.props.children
          }
        </div>

        <div className={"layout__footer-container"}>
          {
            this.props.footer
          }
        </div>
      </div>
    );
  }
}

export default Layout;