import Service from "./Service";

import {API_URL, axiosInstance} from "../config/server.config";

const route = "/contactData";

class ContactService extends Service
{
  constructor ()
  {
    super(route, {
      defaultRoutes: [],
      authenticated: false
    });
  }

  contact = async (body, language) =>
  {
    try
    {
      const config = this._generateConfig(language);

      this.deleteFields(body);

      const result = await axiosInstance.post(API_URL + this._prepareRoute(), body, config);
      return this._extractDataFromSuccess(result);
    }
    catch (error)
    {
      throw this._extractDataFromError(error);
    }
  }
}

export default ContactService;