import React, {Component} from "react";
import {connect} from "react-redux";
import parse from "html-react-parser";

import {setLoading} from "../../store/actions/creators/appActionCreator";

import {API_URL} from "../../config/server.config";
import CommonHelper from "../../libs/CommonHelper";
import i18nRepo from "../../i18n-repo/i18nRepo";

import BlogService from "../../services/BlogService";
import PostContactService from "../../services/PostContactService";

import {message} from "../ui-kit/MessageGate";
import Form from "../ui-kit/Form";
import Input from "../ui-kit/Input";
import TextArea from "../ui-kit/TextArea";
import Button from "../ui-kit/Button";

import {Magazines, SglLogo} from "../../assets";

class ShowPost extends Component
{
  constructor (props)
  {
    super(props);

    this.service = new BlogService();
    this.postContactService = new PostContactService();

    this.formRef = React.createRef();

    this.state = {
      document: null
    };
  }

  async componentDidMount ()
  {
    try
    {
      this.props.setLoading(true);

      if (!CommonHelper.isExist(this.props.location.state) || !CommonHelper.isExist(this.props.location.state._id))
      {
        this.props.history.replace("/insights");
        return;
      }

      const {document} = (await this.service.getByID(this.props.location.state._id, this.props.language));

      this.setState((previousState, props) => ({
        document: document
      }));
    }
    catch (error)
    {
      message({
                type: "error",
                content: i18nRepo.getError("there_is_a_problem_with_getting_the_post")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  }

  onSubmitForm = async (values) =>
  {
    try
    {
      this.props.setLoading(true);

      values._post = this.props.location.state._id;
      await this.postContactService.create(values, this.props.language);

      message({
                type: "success",
                content: i18nRepo.getSentence("thank_you_for_your_opinions")
              });

      this.formRef.current.reset();
    }
    catch (error)
    {
      message({
                type: "error",
                content: i18nRepo.getError("we_could_not_send_your_thoughts")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  };

  onClickSubmit = () =>
  {
    this.formRef.current.submit();
  };

  render ()
  {
    return (
      <div className={"initial-page show-post"}>
        <div className={"show-post__image-wrapper"}>
          {
            CommonHelper.isExist(this.state.document)
            &&
            (
              <img
                className={"show-post__image"}
                src={CommonHelper.isExist(this.state.document.picture)
                     ? API_URL + "/static/uploads/" + this.state.document.picture
                     : Magazines}
              />
            )
          }
        </div>

        {
          CommonHelper.isExist(this.state.document)
          &&
          (
            <div className={"show-post__content-wrapper"}>
              <div className={"show-post__content-subject"}>
                {
                  this.state.document.subject
                }
              </div>

              <div className={"show-post__content-content"}>
                {
                  parse(this.state.document.content)
                }
              </div>
            </div>
          )
        }

        <div className={"show-post__contact-container"}>
          <div className={"show-post__contact-wrapper"}>
            <div className={"show-post__contact-header"}>
              {
                i18nRepo.getSentence("tell_us_what_you_think")
              }
            </div>

            <div className={"flex-basis-100"} />

            <div className={"show-post__contact-form"}>
              <Form
                formItems={[
                  {
                    component: Input,
                    props: {
                      placeholder: "john@doe.com"
                    },
                    fieldName: "email",
                    label: i18nRepo.getWord("email"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("email_required")
                    }
                  },
                  {
                    component: Input,
                    props: {
                      placeholder: "John"
                    },
                    fieldName: "firstName",
                    label: i18nRepo.getWord("first_name"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("first_name_required")
                    }
                  },
                  {
                    component: Input,
                    props: {
                      placeholder: "Doe"
                    },
                    fieldName: "lastName",
                    label: i18nRepo.getWord("last_name"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("last_name_required")
                    }
                  },
                  {
                    component: Input,
                    props: {
                      placeholder: i18nRepo.getWord("phone_placeholder")
                    },
                    fieldName: "phone",
                    label: i18nRepo.getWord("phone"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("phone_required")
                    }
                  },
                  {
                    component: Input,
                    props: {
                      placeholder: i18nRepo.getWord("subject")
                    },
                    fieldName: "subject",
                    label: i18nRepo.getWord("subject"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("subject_required")
                    }
                  },
                  {
                    component: TextArea,
                    props: {
                      placeholder: i18nRepo.getWord("content")
                    },
                    fieldName: "content",
                    label: i18nRepo.getWord("content"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("content_required")
                    }
                  }
                ]}
                ref={this.formRef}
                onSubmit={this.onSubmitForm}
              />
            </div>

            <div className={"show-post__form-button-wrapper"}>
              <Button
                onClick={this.onClickSubmit}
                text={i18nRepo.getWord("send")}
              />
            </div>
          </div>

          {
            !this.props.windowPreferences.isTablet
            &&
            (
              <div className={"show-post__logo-container"}>
                <div className={"show-post__logo-wrapper"}>
                  <SglLogo
                    className={"show-post__logo"}
                  />
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  windowPreferences: state.appReducer.windowPreferences,
  language: state.appReducer.language
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPost);