export const errorRepoEN = {
  content_required: "Content is required.",

  email_required: "Email is required.",

  first_name_required: "First name is required.",

  last_name_required: "Last name is required.",

  phone_required: "Phone is required.",

  subject_required: "Subject is required.",

  there_is_a_problem_with_getting_posts: "There is a problem getting posts. Please try again later.",
  there_is_a_problem_with_getting_the_post: "There is a problem with getting the post.",

  we_could_not_send_your_thoughts: "We could not send your thoughts. Try again later.",

  your_contact_request_could_not_be_registered: "Your contact request could not be registered."
};